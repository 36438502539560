import {
  Component,
  Input,
  Output,
  EventEmitter,
  HostListener,
} from '@angular/core';

@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent {
  // TODO: Rename to select

  // Closes the option if clicked outside of component
  @HostListener('document:click', ['$event'])
  handleDocumentClick(event: MouseEvent) {
    const targetId = (event.target as HTMLElement).id;

    if (targetId !== this.index) {
      this.showOptions = false;
    }
  }

  @Output() output = new EventEmitter<any>();

  @Input() selectedValue: string = '';
  @Input() index: string = '';
  @Input() title: any;
  @Input() options: any;
  @Input() placeholder: any;
  @Input() errorInDropdown: boolean = false;
  @Input() disableDropdown: boolean = false;

  showOptions: boolean = false;

  toggleOptions() {
    this.showOptions = !this.showOptions;
  }

  optionClick(selectedOption: any) {
    // KSF have they own values "valuesText"
    this.selectedValue = selectedOption.name || selectedOption.valueText;
    this.output.emit(selectedOption);
    this.toggleOptions();
  }
}
