import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-success-step',
  templateUrl: './success-step.component.html',
  styleUrls: ['./success-step.component.scss'],
})
export class SuccessStepComponent {
  @Output() resetEvent = new EventEmitter();

  @Input() make: string = '';
  @Input() model: string = '';
  @Input() InsurranceCompany: string = '';
  @Input() activeStep: number = 0;
  @Input() textDirectedToCustomer: boolean = false;

  constructor() {}

  reset() {
    this.resetEvent.emit();
  }
}
