<div class="w-full relative text-#1F2937">
  <p class="font-inter text-[#042F2E] text-sm mb-1">
    {{ title }}
  </p>
  <button
    [ngClass]="{
      'border-red-500': errorInDropdown,
      'border-[#1FAD96]': !errorInDropdown && selectedValue.length > 0,
      'border-gray-200': !errorInDropdown && selectedValue.length == 0,
    }"
    [disabled]="options.length == 0 || disableDropdown"
    [id]="index"
    (click)="toggleOptions()"
    class="w-full justify-between inline-flex items-center whitespace-nowrap py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border bg-white text-[#1F2937] shadow-sm hover:bg-gray-50 focus:border-[#1FAD96] focus:outline-none focus:ring-2 focus:ring-teal-500/25 disabled:opacity-50 disabled:pointer-events-none"
  >
    <!-- ### the current task status-->

    <ng-container *ngIf="selectedValue.length <= 0">
      <span [id]="index" class="font-normal">
        {{ placeholder | truncate: 25 }}</span
      >
    </ng-container>
    <ng-container class="font-medium" *ngIf="selectedValue.length > 0">
      {{ selectedValue | truncate: 25 }}
    </ng-container>

    <!-- ####  -->
    <i
      [id]="index"
      class="fa-solid fa-chevron-down text-sm"
      [class.rotate-180]="showOptions"
    ></i>
  </button>

  <!-- Dropdown menu -->
  <div
    [id]="index"
    class="absolute border font-semibold px-2 py-2 w-full z-50 mt-2 bg-white rounded-md shadow-lg fade-in-out max-h-96 overflow-y-auto"
    [class.fade-in-out-hidden]="!showOptions"
  >
    <!-- Dropdown items -->
    <a
      [id]="index"
      (click)="optionClick(option)"
      *ngFor="let option of options"
      class="block px-2 py-2 rounded-md hover:bg-[#F3F4F6] font-inter text-sm"
    >
      <ng-container *ngIf="option.name; else valueTextTemplate">
        {{ option.name }}
      </ng-container>
      <ng-template #valueTextTemplate>
        <!-- KSF have they own values "valuesText"-->
        {{ option.valueText }}
      </ng-template>
    </a>

    <!-- Add more items as needed -->
  </div>
</div>
