import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { LoginService } from '../login/login.service';
import { Router } from '@angular/router';
import { environment } from 'projects/shared/src/environments/environment';
import { ApiService } from '../api-service/api.service';

@Injectable({
  providedIn: 'root',
})
export class DmrService {
  constructor(
    private router: Router,
    private loginService: LoginService,
    private apiService: ApiService
  ) {}

  /**
   * Retrieves vehicle information using the provided registration number.
   * This can be called with or without authentication, default is true.
   *
   * @param {string} regNumber - The registration number of the vehicle to look up.
   * @param {boolean} [requireAuth=true] - Whether authentication is required for the request.
   * @returns {Observable<any>} - An Observable that emits the vehicle information or an error if the request fails.
   */
  getVehicleByRegNumber(
    regNumber: string,
    requireAuth: boolean = true
  ): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    if (requireAuth) {
      const accessToken = this.loginService.getActiveToken();
      const refreshToken = this.loginService.getRefreshToken();

      if (!accessToken || !refreshToken) {
        console.error('Error with tokens in getVehicleByRegNumber');
        this.loginService.clearTokens();
        this.router.navigate(['/login']);
        return of(null);
      }

      const isExpired = this.loginService.isTokenExpired(accessToken);

      if (isExpired) {
        return this.loginService.refreshAccessToken(refreshToken).pipe(
          switchMap(() => {
            headers = headers.set('Authorization', `Bearer ${accessToken}`);
            return this.apiService.makeApiCall<any>(
              `${environment.apiGetVehicleFromDMRByReg}${regNumber}`,
              headers,
              'GET'
            );
          }),
          catchError((error) => {
            console.error('Error refreshing token', error);
            this.loginService.clearTokens();
            this.router.navigate(['/login']);
            return throwError(() => error);
          })
        );
      }

      headers = headers.set('Authorization', `Bearer ${accessToken}`);
    }

    return this.apiService.makeApiCall<any>(
      `${environment.apiGetVehicleFromDMRByReg}${regNumber}`,
      headers,
      'GET'
    );
  }

  // This can be called with or without auth check - deafult is true

  /**
   * Retrieves vehicle information using the provided VIN number.
   * This can be called with or without authentication, default is true.
   *
   * @param {string} vinNumber - The VIN number of the vehicle to look up.
   * @param {boolean} [requireAuth=true] - Whether authentication is required for the request.
   * @returns {Observable<any>} - An Observable that emits the vehicle information or an error if the request fails.
   */
  getVehicleByVINNumber(
    vinNumber: string,
    requireAuth: boolean = true
  ): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    if (requireAuth) {
      const accessToken = this.loginService.getActiveToken();
      const refreshToken = this.loginService.getRefreshToken();

      if (!accessToken || !refreshToken) {
        console.error('Error with tokens in getVehicleByVINNumber');
        this.loginService.clearTokens();
        this.router.navigate(['/login']);
        return of(null);
      }

      const isExpired = this.loginService.isTokenExpired(accessToken);

      if (isExpired) {
        return this.loginService.refreshAccessToken(refreshToken).pipe(
          switchMap(() => {
            headers = headers.set('Authorization', `Bearer ${accessToken}`);
            return this.apiService.makeApiCall<any>(
              `${environment.apiGetVehicleFromDMRByVIN}${vinNumber}`,
              headers,
              'GET'
            );
          }),
          catchError((error) => {
            console.error('Error refreshing token', error);
            this.loginService.clearTokens();
            this.router.navigate(['/login']);
            return throwError(() => error);
          })
        );
      }

      headers = headers.set('Authorization', `Bearer ${accessToken}`);
    }

    return this.apiService.makeApiCall<any>(
      `${environment.apiGetVehicleFromDMRByVIN}${vinNumber}`,
      headers,
      'GET'
    );
  }

  /**
   * Scrapes vehicle information using the provided registration number.
   * This can be called with or without authentication, default is true.
   *
   * @param {string} regNumber - The registration number of the vehicle to scrape information for.
   * @param {boolean} [requireAuth=true] - Whether authentication is required for the request.
   * @returns {Observable<any>} - An Observable that emits the scraped vehicle information or an error if the request fails.
   */
  scrapeVehicleByRegNumber(
    regNumber: string,
    requireAuth: boolean = true
  ): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    if (requireAuth) {
      const accessToken = this.loginService.getActiveToken();
      const refreshToken = this.loginService.getRefreshToken();

      if (!accessToken || !refreshToken) {
        console.error('Error with tokens in scrapeVehicleByRegNumber');
        this.loginService.clearTokens();
        this.router.navigate(['/login']);
        return of(null);
      }

      const isExpired = this.loginService.isTokenExpired(accessToken);

      if (isExpired) {
        return this.loginService.refreshAccessToken(refreshToken).pipe(
          switchMap(() => {
            headers = headers.set('Authorization', `Bearer ${accessToken}`);
            return this.apiService.makeApiCall<any>(
              `${environment.apiScrapeVehicleFromDMRByReg}${regNumber}`,
              headers,
              'GET'
            );
          }),
          catchError((error) => {
            console.error('Error refreshing token', error);
            this.loginService.clearTokens();
            this.router.navigate(['/login']);
            return throwError(() => error);
          })
        );
      }

      headers = headers.set('Authorization', `Bearer ${accessToken}`);
    }

    return this.apiService.makeApiCall<any>(
      `${environment.apiScrapeVehicleFromDMRByReg}${regNumber}`,
      headers,
      'GET'
    );
  }

  /**
   * Scrapes vehicle information using the provided VIN number.
   * This can be called with or without authentication, default is true.
   *
   * @param {string} vinNumber - The VIN number of the vehicle to scrape information for.
   * @param {boolean} [requireAuth=true] - Whether authentication is required for the request.
   * @returns {Observable<any>} - An Observable that emits the scraped vehicle information or an error if the request fails.
   */
  scrapeVehicleByVINNumber(
    vinNumber: string,
    requireAuth: boolean = true
  ): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    if (requireAuth) {
      const accessToken = this.loginService.getActiveToken();
      const refreshToken = this.loginService.getRefreshToken();

      if (!accessToken || !refreshToken) {
        console.error('Error with tokens in scrapeVehicleByVINNumber');
        this.loginService.clearTokens();
        this.router.navigate(['/login']);
        return of(null);
      }

      const isExpired = this.loginService.isTokenExpired(accessToken);

      if (isExpired) {
        return this.loginService.refreshAccessToken(refreshToken).pipe(
          switchMap(() => {
            headers = headers.set('Authorization', `Bearer ${accessToken}`);
            return this.apiService.makeApiCall<any>(
              `${environment.apiScrapeVehicleFromDMRByVIN}${vinNumber}`,
              headers,
              'GET'
            );
          }),
          catchError((error) => {
            console.error('Error refreshing token', error);
            this.loginService.clearTokens();
            this.router.navigate(['/login']);
            return throwError(() => error);
          })
        );
      }

      headers = headers.set('Authorization', `Bearer ${accessToken}`);
    }

    return this.apiService.makeApiCall<any>(
      `${environment.apiScrapeVehicleFromDMRByVIN}${vinNumber}`,
      headers,
      'GET'
    );
  }
}
