import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-error-price-card',
  templateUrl: './error-price-card.component.html',
  styleUrls: ['./error-price-card.component.css'],
})
export class ErrorPriceCardComponent {
  @Input() company: string = '';
  @Input() msg: string = '';
}
