// projects/shared/src/lib/services/dealer.service.ts

import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { LoginService } from '../login/login.service';
import { ApiService } from '../api-service/api.service';
import { Router } from '@angular/router';
import { ICreateDealer, IUpdateDealer } from 'projects/shared/src/public-api';
import { environment } from 'projects/shared/src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class DealerService {
  constructor(
    private loginService: LoginService,
    private apiService: ApiService,
    private router: Router
  ) {}

  /**
   * Retrieves the list of dealers from the server.
   *
   * @param {boolean} [requireAuth=true] - Whether to check for authorization tokens.
   * @returns {Observable<any>} - An Observable that emits the list of dealers
   * or an error if the request fails.
   */
  getDealers(requireAuth: boolean = true): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    if (requireAuth) {
      const accessToken = this.loginService.getActiveToken();
      const refreshToken = this.loginService.getRefreshToken();

      if (!accessToken || !refreshToken) {
        console.error('Error with tokens in getDealers');
        this.loginService.clearTokens();
        this.router.navigate(['/login']);
        return of(null);
      }

      const isExpired = this.loginService.isTokenExpired(accessToken);

      if (isExpired) {
        return this.loginService.refreshAccessToken(refreshToken).pipe(
          switchMap(() => {
            const refreshedAccessToken = this.loginService.getActiveToken();
            headers = headers.set(
              'Authorization',
              `Bearer ${refreshedAccessToken}`
            );

            return this.apiService.makeApiCall<any>(
              environment.apiGetDealers,
              headers,
              'GET'
            );
          }),
          catchError((error) => {
            console.error('Error refreshing token', error);
            this.loginService.clearTokens();
            this.router.navigate(['/login']);
            return throwError(() => error);
          })
        );
      }

      // If the token is not expired, proceed with the API call
      headers = headers.set('Authorization', `Bearer ${accessToken}`);
    }

    // Proceed with the API call directly if auth is not required
    return this.apiService.makeApiCall<any>(
      environment.apiGetDealers,
      headers,
      'GET'
    );
  }

  /**
   * Creates a new dealer on the server.
   *
   * @param {ICreateDealer} dealer - The dealer object containing the information to be created.
   * @returns {Observable<any>} - An Observable that emits the server's response
   * or an error if the request fails.
   */
  createDealer(dealer: ICreateDealer): Observable<any> {
    const accessToken = this.loginService.getActiveToken();
    const refreshToken = this.loginService.getRefreshToken();

    if (!accessToken || !refreshToken) {
      console.error('Error with tokens in createDealer');
      this.loginService.clearTokens();
      this.router.navigate(['/login']);
      return of(null);
    }

    const isExpired = this.loginService.isTokenExpired(accessToken);

    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    });

    if (isExpired) {
      return this.loginService.refreshAccessToken(refreshToken).pipe(
        switchMap(() => {
          const refreshedAccessToken = this.loginService.getActiveToken();
          headers = headers.set(
            'Authorization',
            `Bearer ${refreshedAccessToken}`
          );

          return this.apiService.makeApiCall<any>(
            environment.apiCreateDealer,
            headers,
            'POST',
            dealer
          );
        }),
        catchError((error) => {
          console.error('Error refreshing token', error);
          this.loginService.clearTokens();
          this.router.navigate(['/login']);
          return throwError(() => error);
        })
      );
    }

    // Token not expired, proceed with the API call
    return this.apiService.makeApiCall<any>(
      environment.apiCreateDealer,
      headers,
      'POST',
      dealer
    );
  }

  /**
   * Retrieves dealer information by ID from the server.
   *
   * @param {number} dealerId - The ID of the dealer to retrieve.
   * @returns {Observable<any>} - An Observable that emits the dealer information
   * or an error if the request fails.
   */
  getDealerById(dealerId: number): Observable<any> {
    // Get tokens
    const accessToken = this.loginService.getActiveToken();
    const refreshToken = this.loginService.getRefreshToken();

    if (accessToken && refreshToken) {
      const isExpired = this.loginService.isTokenExpired(accessToken);

      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      });

      if (isExpired) {
        return this.loginService.refreshAccessToken(refreshToken).pipe(
          switchMap(() => {
            // Refresh access token, then make the API call with the updated token
            const refreshedAccessToken = this.loginService.getActiveToken();
            const refreshedHeaders = headers.set(
              'Authorization',
              `Bearer ${refreshedAccessToken}`
            );

            // Use the helper method for API call
            return this.apiService.makeApiCall<any>(
              `${environment.apiGetDealerByID}${dealerId}`,
              refreshedHeaders,
              'GET'
            );
          }),
          catchError((error) => {
            console.error('Error refreshing token', error);
            this.loginService.clearTokens();
            this.router.navigate(['/login']);
            return throwError(() => error);
          })
        );
      }

      // Token not expired, proceed with API call
      return this.apiService.makeApiCall<any>(
        `${environment.apiGetDealerByID}${dealerId}`,
        headers,
        'GET'
      );
    } else {
      console.error('Error with token in getDealerById');
      this.loginService.clearTokens();
      this.router.navigate(['/login']);
      return of(null);
    }
  }

  /**
   * Deletes a dealer by ID.
   *
   * @param {number} dealerId - The ID of the dealer to delete.
   * @returns {Observable<any>} - An Observable that emits the result of the deletion
   * or an error if the request fails.
   */
  deleteDealer(dealerId: number): Observable<any> {
    const accessToken = this.loginService.getActiveToken();
    const refreshToken = this.loginService.getRefreshToken();

    if (accessToken && refreshToken) {
      const isExpired = this.loginService.isTokenExpired(accessToken);

      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      });

      if (isExpired) {
        return this.loginService.refreshAccessToken(refreshToken).pipe(
          switchMap((newAccessToken) => {
            headers = headers.set('Authorization', `Bearer ${newAccessToken}`);
            return this.apiService.makeApiCall<any>(
              environment.apiDeleteDealerByID + dealerId,
              headers,
              'DELETE'
            );
          }),
          catchError((error) => {
            console.error('Error refreshing token', error);
            this.loginService.clearTokens();
            this.router.navigate(['/login']);
            return throwError(() => error);
          })
        );
      }

      return this.apiService.makeApiCall<any>(
        environment.apiDeleteDealerByID + dealerId,
        headers,
        'DELETE'
      );
    } else {
      console.error('Error with token in deleteDealer');
      this.loginService.clearTokens();
      this.router.navigate(['/login']);
      return of(null);
    }
  }

  /**
   * Restores a deleted dealer by their ID.
   *
   * @param {number} dealerId - The ID of the dealer to restore.
   * @returns {Observable<any>} - An Observable that emits the result of the restoration
   * or an error if the request fails.
   */
  restoreDealer(dealerId: number): Observable<any> {
    const accessToken = this.loginService.getActiveToken();
    const refreshToken = this.loginService.getRefreshToken();

    if (accessToken && refreshToken) {
      const isExpired = this.loginService.isTokenExpired(accessToken);

      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      });

      if (isExpired) {
        return this.loginService.refreshAccessToken(refreshToken).pipe(
          switchMap((newAccessToken) => {
            headers = headers.set('Authorization', `Bearer ${newAccessToken}`);
            return this.apiService.makeApiCall<any>(
              environment.apiRestoreDealer + dealerId,
              headers,
              'PUT'
            );
          }),
          catchError((error) => {
            console.error('Error refreshing token', error);
            this.loginService.clearTokens();
            this.router.navigate(['/login']);
            return throwError(() => error);
          })
        );
      }

      return this.apiService.makeApiCall<any>(
        environment.apiRestoreDealer + dealerId,
        headers,
        'PUT'
      );
    } else {
      console.error('Error with token in restoreDealer');
      this.loginService.clearTokens();
      this.router.navigate(['/login']);
      return of(null);
    }
  }

  /**
   * Updates the dealer information on the server.
   *
   * @param {IUpdateDealer} dealer - The dealer object containing updated information.
   * @returns {Observable<any>} - An Observable that emits the server's response
   * or an error if the request fails.
   */
  updateDealer(dealer: IUpdateDealer): Observable<any> {
    // Get tokens
    const accessToken = this.loginService.getActiveToken();
    const refreshToken = this.loginService.getRefreshToken();

    if (accessToken && refreshToken) {
      const isExpired = this.loginService.isTokenExpired(accessToken);

      let headers = new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      });

      if (isExpired) {
        // Token expired, refresh it
        return this.loginService.refreshAccessToken(refreshToken).pipe(
          switchMap((newAccessToken) => {
            // Set refreshed token in headers
            headers = headers.set('Authorization', `Bearer ${newAccessToken}`);
            // Use the helper method for API call after refreshing token
            return this.apiService.makeApiCall<any>(
              environment.apiUpdateDealer,
              headers,
              'PUT',
              dealer
            );
          }),
          catchError((error) => {
            console.error('Error refreshing token', error);
            this.loginService.clearTokens();
            this.router.navigate(['/login']);
            return throwError(() => error);
          })
        );
      }

      // Token not expired, proceed with API call
      return this.apiService.makeApiCall<any>(
        environment.apiUpdateDealer,
        headers,
        'PUT',
        dealer
      );
    } else {
      console.error('Error with token in updateDealer');
      this.loginService.clearTokens();
      this.router.navigate(['/login']);
      return of(null);
    }
  }
}
