<div *ngIf="activeStep == 2">
  <lib-helvetia-price-card
    [errorIngetInsurancePrices]="errorIngetInsurancePrices"
    [errorInHelvetiaPrice]="errorInHelvetiaPrice"
    [allHaveTogglePricetag]="allHaveTogglePricetag"
    [showYearlyPrice]="showYearlyPrice"
    [helvetiaMonthlyPrice]="helvetiaMonthlyPrice"
    [helvetiaYearlyPrice]="helvetiaYearlyPrice"
    [helvetiaVariants]="helvetiaVariants"
    [selectedHelvetiaVariant]="selectedHelvetiaVariant"
    [vehicleFromDmr]="vehicleFromDmr"
    (pickHelvetiaEvent)="pickHelvetia($event)"
    (setYearlyAndMonthlyPriceEvent)="setYearlyAndMonthlyPrice()"
    (setSelectedHelvetiaVariantEvent)="setSelectedHelvetiaVariant($event)"
  ></lib-helvetia-price-card>
</div>
<!-- ######  Step 3 Helvetia ###### -->
<div
  *ngIf="showHelvetiaInfo && activeStep > 2"
  class="max-w-[85rem] lg:px-8 py-10 mx-auto"
>
  <div *ngIf="activeStep == 3 && showHelvetiaInfo">
    <div class="grid md:grid-cols-2">
      <lib-helvetia-info
        [helvetiaVariants]="helvetiaVariants"
        [loadinginsuranceInfo]="loadinginsuranceInfo"
        [annualMileagePriceUpdate]="annualMileagePriceUpdate"
        [selectedNumberOfDamages]="selectedNumberOfDamages"
        [selectedAnnualMileage]="selectedAnnualMileage"
        (recalculateHelvetiaPrices)="recalculateHelvetiaPrices()"
        (setSelectedNumberOfDamages)="selectedNumberOfDamages = $event"
        (setSelectedAnnualMileage)="selectedAnnualMileage = $event"
        (nextStepEvent)="nextStep()"
      ></lib-helvetia-info>

      <lib-helvetia-side-display
        [showYearlyPrice]="showYearlyPrice"
        [helvetiaMonthlyPrice]="helvetiaMonthlyPrice"
        [helvetiaYearlyPrice]="helvetiaYearlyPrice"
        [loadinginsuranceInfo]="loadinginsuranceInfo"
        (togglePriceIntervalEvent)="togglePriceInterval()"
      ></lib-helvetia-side-display>
    </div>
  </div>

  <div *ngIf="activeStep == 4 && showHelvetiaInfo">
    <div class="grid md:grid-cols-2">
      <lib-helvetia-coverage-selection
        [selectedHelvetiaVariant]="selectedHelvetiaVariant"
        [showYearlyPrice]="showYearlyPrice"
        [textDirectedToCustomer]="true"
        [glassCover]="glassCover"
        [parkingCoverage]="parkingCoverage"
        [roadsideAssistance]="roadsideAssistance"
        [chargingStand]="chargingStand"
        [vehicleFromDmr]="vehicleFromDmr"
        [allHaveToggleChargingStandAllowed]="allHaveToggleChargingStandAllowed"
        (setGlassCoverEvent)="setGlassCover($event)"
        (setParkingCoverageEvent)="setParkingCoverage($event)"
        (setRoadsideAssistanceEvent)="setRoadsideAssistance($event)"
        (setChargingStandEvent)="setChargingStand($event)"
        (nextStepEvent)="nextStep()"
        (cvrLookupEvent)="lookupCVR()"
        (cprExtraUserLookupEvent)="lookupExtraUserCVR()"
      >
      </lib-helvetia-coverage-selection>

      <lib-helvetia-side-display
        [showYearlyPrice]="showYearlyPrice"
        [helvetiaMonthlyPrice]="helvetiaMonthlyPrice"
        [helvetiaYearlyPrice]="helvetiaYearlyPrice"
        [loadinginsuranceInfo]="loadinginsuranceInfo"
        (togglePriceIntervalEvent)="togglePriceInterval()"
      ></lib-helvetia-side-display>
    </div>
  </div>

  <div *ngIf="activeStep == 5 && showHelvetiaInfo">
    <div class="grid md:grid-cols-2">
      <lib-helvetia-customer-info
        [userId]="userId"
        [disableCprLookup]="true"
        [textDirectedToCustomer]="true"
        [cvrLoading]="cvrLoading"
        [errorInCvrNumber]="errorInCvrNumber"
        [showAddressOption]="showAddressOption"
        [extraUserCvrLoading]="extraUserCvrLoading"
        [errorInExtraUserCompanyName]="errorInExtraUserCompanyName"
        [errorInExtraUserCvrNumber]="errorInExtraUserCvrNumber"
        [showExtraUserAddressOption]="showExtraUserAddressOption"
        [loadinginsuranceInfo]="loadinginsuranceInfo"
        [helvetiaCustomerInfo]="helvetiaCustomerInfo"
        (newAgeBasedOnExtraUserCprEvent)="setNewExtraUserAge($event)"
        (recalculateHelvetiaPricesEvent)="recalculateHelvetiaPrices()"
        (cvrLookupEvent)="lookupCVR()"
        (cprExtraUserLookupEvent)="lookupExtraUserCVR()"
        (newAgeBasedOnCprEvent)="setNewAge($event)"
        (setCustomerInfoEvent)="helvetiaCustomerInfo = $event"
        (nextStepEvent)="nextStep()"
      >
      </lib-helvetia-customer-info>

      <lib-helvetia-side-display
        [showYearlyPrice]="showYearlyPrice"
        [helvetiaMonthlyPrice]="helvetiaMonthlyPrice"
        [helvetiaYearlyPrice]="helvetiaYearlyPrice"
        [loadinginsuranceInfo]="loadinginsuranceInfo"
        (togglePriceIntervalEvent)="togglePriceInterval()"
      ></lib-helvetia-side-display>
    </div>
  </div>

  <div *ngIf="activeStep == 6 && showHelvetiaInfo">
    <lib-helvetia-accept-conditions
      [chargingStand]="chargingStand"
      [textDirectedToCustomer]="true"
      [helvetiaAcceptConditions]="helvetiaAcceptConditions"
      [applyForInsuranceLoading]="applyForInsuranceLoading"
      (applyForHelvetiaEvent)="applyForHelvetia()"
      (nextStepEvent)="nextStep()"
    ></lib-helvetia-accept-conditions>
  </div>

  <div *ngIf="activeStep == 7 && showHelvetiaInfo">
    <lib-success-step
      [make]="vehicleFromDmr.make"
      [model]="vehicleFromDmr.model"
      [InsurranceCompany]="'helvetia'"
      [activeStep]="activeStep"
      [textDirectedToCustomer]="true"
      (resetEvent)="reset()"
    ></lib-success-step>
  </div>
</div>
