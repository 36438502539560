import { Injectable } from '@angular/core';
import { HelvetiaCoverage } from './models/helvetia.model';
import { MatDialog } from '@angular/material/dialog';
import { NotificationDialogComponent } from './components/notification-dialog/notification-dialog.component';

@Injectable({
  providedIn: 'root',
})
export class LibUtilityService {
  constructor(private dialog: MatDialog) {}

  getHelvetiaPrice(
    coverages: HelvetiaCoverage[],
    period: 'monthly' | 'yearly'
  ): number {
    const TPL: HelvetiaCoverage | undefined = coverages.find(
      (coverage) => coverage.coverageName === 'TPL'
    );

    const OMNIUM: HelvetiaCoverage | undefined = coverages.find(
      (coverage) => coverage.coverageName === 'OMNIUM'
    );

    if (!TPL || !OMNIUM) {
      throw new Error('Required coverage not found');
    }

    const totalCoverage =
      period === 'yearly'
        ? (OMNIUM.yearlyPremiumWithTaxes || 0) +
          (TPL.yearlyPremiumWithTaxes || 0)
        : (OMNIUM.monthlyPremiumWithTaxes || 0) +
          (TPL.monthlyPremiumWithTaxes || 0);

    let totalPriceInKroner = totalCoverage / 100;

    totalPriceInKroner = Math.round(totalPriceInKroner);

    return totalPriceInKroner;
  }

  getAddonsPrices(
    parkingCoverage: boolean,
    roadsideAssistance: boolean,
    glassCover: boolean,
    coverages: HelvetiaCoverage[],
    showYearlyPrice: boolean
  ) {
    let combinedAddonPrice = 0;

    if (parkingCoverage) {
      const parkingCoveragePrice = this.getHelvetiaParkingCoveragePrice(
        coverages,
        showYearlyPrice
      );
      combinedAddonPrice = combinedAddonPrice + parkingCoveragePrice;
    }

    if (roadsideAssistance) {
      const roadsideAssistancePrice = this.getHelvetiaRoadsideAssistancePrice(
        coverages,
        showYearlyPrice
      );

      combinedAddonPrice = combinedAddonPrice + roadsideAssistancePrice;
    }

    if (glassCover) {
      const glassCoverPrice = this.getHelvetiaGlassCoverPrice(
        coverages,
        showYearlyPrice
      );

      combinedAddonPrice = combinedAddonPrice + glassCoverPrice;
    }

    return combinedAddonPrice;
  }

  getHelvetiaParkingCoveragePrice(
    coverages: any[],
    showYearlyPrice: boolean
  ): number {
    const totalCoverage: HelvetiaCoverage = coverages.find(
      (coverage) => coverage.coverageName === 'ADDON_TYPE_PARKING_DAMAGE'
    );

    if (!showYearlyPrice) {
      const totalPriceInKroner = totalCoverage.monthlyPremiumWithTaxes / 100;
      return Math.round(totalPriceInKroner);
    } else {
      const totalPriceInKroner = totalCoverage.yearlyPremiumWithTaxes / 100;
      return Math.round(totalPriceInKroner);
    }
  }

  getHelvetiaGlassCoverPrice(
    coverages: any[],
    showYearlyPrice: boolean
  ): number {
    const totalCoverage: HelvetiaCoverage = coverages.find(
      (coverage) =>
        coverage.coverageName === 'ADDON_TYPE_ADDITIONAL_GLASS_COVER'
    );

    if (!showYearlyPrice) {
      const totalPriceInKroner = totalCoverage.monthlyPremiumWithTaxes / 100;
      return Math.round(totalPriceInKroner);
    } else {
      const totalPriceInKroner = totalCoverage.yearlyPremiumWithTaxes / 100;
      return Math.round(totalPriceInKroner);
    }
  }

  getHelvetiaRoadsideAssistancePrice(
    coverages: any[],
    showYearlyPrice: boolean
  ): number {
    const totalCoverage: HelvetiaCoverage = coverages.find(
      (coverage) => coverage.coverageName === 'ADDON_TYPE_ASSISTANCE'
    );

    if (!showYearlyPrice) {
      const totalPriceInKroner = totalCoverage.monthlyPremiumWithTaxes / 100;
      return Math.round(totalPriceInKroner);
    } else {
      const totalPriceInKroner = totalCoverage.yearlyPremiumWithTaxes / 100;
      return Math.round(totalPriceInKroner);
    }
  }

  notification(type: string, title: string, message: string) {
    this.dialog.open(NotificationDialogComponent, {
      data: {
        message: message,
        type: type,
        title: title,
      },
    });
  }
}
