import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HelvetiaAcceptConditions } from '../../models/helvetia.model';

@Component({
  selector: 'lib-helvetia-accept-conditions',
  templateUrl: './helvetia-accept-conditions.component.html',
  styleUrls: ['./helvetia-accept-conditions.component.scss'],
})
export class HelvetiaAcceptConditionsComponent {
  @Output() nextStepEvent = new EventEmitter();
  @Output() applyForHelvetiaEvent = new EventEmitter();

  @Input() chargingStand: boolean = false;
  @Input() textDirectedToCustomer: boolean = false;
  @Input() applyForInsuranceLoading: boolean = false;
  @Input() helvetiaAcceptConditions: HelvetiaAcceptConditions = {
    acceptedTradingConditions: false,
    acceptedInfoIsCorrect: false,
    acceptedConsent: false,
    acceptedAsgMarketing: false,
    acceptedChargingStandConsultant: false,
    acceptedChargingStandCustomer: false,
  };

  errorInChargingStandCustomer: boolean = false;
  errorInChargingStandConsultant: boolean = false;
  errorInTradingConditionsCheckbox: boolean = false;
  errorInInfoIsCorrecCheckbox: boolean = false;
  errorInConsentCheckbox: boolean = false;

  constructor() {}

  resetHelvetiaInfo() {
    this.helvetiaAcceptConditions = {
      acceptedTradingConditions: false,
      acceptedInfoIsCorrect: false,
      acceptedConsent: false,
      acceptedAsgMarketing: false,
      acceptedChargingStandConsultant: false,
      acceptedChargingStandCustomer: false,
    };

    this.errorInChargingStandCustomer = false;
    this.errorInChargingStandConsultant = false;
    this.errorInTradingConditionsCheckbox = false;
    this.errorInInfoIsCorrecCheckbox = false;
    this.errorInConsentCheckbox = false;
    this.applyForInsuranceLoading = false;
  }

  applyForHelvetia() {
    this.errorInTradingConditionsCheckbox = false;
    this.errorInInfoIsCorrecCheckbox = false;
    this.errorInConsentCheckbox = false;
    this.errorInChargingStandCustomer = false;
    this.errorInChargingStandConsultant = false;
    if (
      !this.helvetiaAcceptConditions.acceptedTradingConditions ||
      !this.helvetiaAcceptConditions.acceptedInfoIsCorrect ||
      !this.helvetiaAcceptConditions.acceptedConsent ||
      (this.chargingStand &&
        !this.helvetiaAcceptConditions.acceptedChargingStandCustomer) ||
      (this.chargingStand &&
        !this.helvetiaAcceptConditions.acceptedChargingStandConsultant)
    ) {
      this.errorInTradingConditionsCheckbox =
        !this.helvetiaAcceptConditions.acceptedTradingConditions;
      this.errorInInfoIsCorrecCheckbox =
        !this.helvetiaAcceptConditions.acceptedInfoIsCorrect;
      this.errorInConsentCheckbox =
        !this.helvetiaAcceptConditions.acceptedConsent;
      this.errorInChargingStandCustomer =
        this.chargingStand &&
        !this.helvetiaAcceptConditions.acceptedChargingStandCustomer;
      this.errorInChargingStandConsultant =
        this.chargingStand &&
        !this.helvetiaAcceptConditions.acceptedChargingStandConsultant;

      // Error in Conditions
      return;
    }

    this.applyForHelvetiaEvent.emit();
  }
}
