import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { LoginService } from '../login/login.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(
    private http: HttpClient,
    private loginService: LoginService,
    private router: Router
  ) {}

  makeApiCall<T>(
    url: string,
    headers: HttpHeaders,
    method: 'GET' | 'POST' | 'PUT' | 'DELETE',
    body?: any
  ): Observable<T> {
    let request$: Observable<T>;

    // Handle different HTTP methods
    switch (method) {
      case 'GET':
        request$ = this.http.get<T>(url, { headers, params: body });
        break;
      case 'POST':
        request$ = this.http.post<T>(url, body, { headers });
        break;
      case 'PUT':
        request$ = this.http.put<T>(url, body, { headers });
        break;
      case 'DELETE':
        request$ = this.http.delete<T>(url, { headers });
        break;
      default:
        throw new Error('Invalid HTTP method');
    }

    return request$.pipe(
      map((result) => {
        // Check for renewed token if applicable
        this.loginService.checkForRenewedToken(result);
        return result;
      }),
      catchError((error) => {
        // Handle 401 Unauthorized (Expired Token)
        if (error.status === 401) {
          this.loginService.clearTokens();
          this.router.navigate(['/login']);
        }
        console.error(error);
        return throwError(() => error);
      })
    );
  }
}
