<div class="w-56 relative text-#1F2937">
  <p class="font-inter text-[#042F2E] text-sm mb-1">
    {{ title }}
  </p>
  <div class="relative flex items-center">
    <input
      [(ngModel)]="selectedValue"
      (keyup)="filterOptions()"
      type="text"
      [ngClass]="{
        'border-red-500': errorInDropdown,
        'border-[#1FAD96]': !errorInDropdown && selectedValue.length > 0,
        'border-gray-200': !errorInDropdown && selectedValue.length == 0,
      }"
      [disabled]="options.length == 0"
      [id]="index"
      (click)="toggleOptions()"
      class="w-full py-3 pl-4 pr-10 text-sm font-medium rounded-lg border bg-white text-[#1F2937] shadow-sm hover:bg-gray-50 focus:border-[#1FAD96] focus:outline-none focus:ring-2 focus:ring-teal-500/25 disabled:opacity-50 disabled:pointer-events-none"
      [value]="selectedValue"
      autocomplete="off"
    />
    <i
      [id]="index"
      class="fa-solid fa-chevron-down absolute right-3 text-sm pointer-events-none"
      [class.rotate-180]="showOptions"
    ></i>
  </div>

  <!-- Dropdown menu -->
  <div
    [id]="index"
    class="absolute border font-semibold px-2 py-2 w-full z-50 mt-2 bg-white rounded-md shadow-lg fade-in-out max-h-96 overflow-y-auto"
    [class.fade-in-out-hidden]="!showOptions"
  >
    <span
      class="block px-2 py-2 rounded-md"
      *ngIf="filteredOptions.length == 0"
    >
      <!-- No values if the searched value-->
      Ingen værdier fundet
    </span>
    <!-- Dropdown items -->
    <a
      [id]="index"
      (click)="optionClick(option)"
      (mousedown)="preventFocus($event)"
      *ngFor="let option of filteredOptions"
      class="block px-2 py-2 rounded-md hover:bg-[#F3F4F6] font-inter text-sm"
    >
      <ng-container *ngIf="option.name; else valueTextTemplate">
        {{ option.name }}
      </ng-container>
      <ng-template #valueTextTemplate>
        <!-- KSF have they own values "valuesText"-->
        {{ option.valueText }}
      </ng-template>
    </a>
  </div>
</div>
