<!-- Card -->
<div class="mb-6">
  <div
    class="py-6 first:pt-0 last:pb-0 border-t first:border-transparent border-gray-200 dark:border-gray-700 dark:first:border-transparent"
  >
    <div class="font-syne text-2xl font-semibold mb-5">
      <span *ngIf="textDirectedToCustomer">Dine</span>
      <span *ngIf="!textDirectedToCustomer">Kundens</span> oplysninger
    </div>

    <!-- ##### show as business toggle ######## -->
    <div class="flex space-x-4 my-5">
      <button
        (click)="helvetiaCustomerInfo.showAsBusinessCustomer = false"
        [ngClass]="{
          'bg-[#F1FCF9] border-[#1FAD96]':
            !helvetiaCustomerInfo.showAsBusinessCustomer,
          'bg-white border-gray-100':
            helvetiaCustomerInfo.showAsBusinessCustomer
        }"
        class="h-32 w-40 text-center rounded-lg border flex flex-col items-center justify-center space-y-2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="1"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="lucide lucide-user"
        >
          <path d="M19 21v-2a4 4 0 0 0-4-4H9a4 4 0 0 0-4 4v2" />
          <circle cx="12" cy="7" r="4" />
        </svg>
        <p class="font-inter text-sm py-2">Privat</p>
        <input
          id="privat"
          name="notification-method"
          type="radio"
          [checked]="!helvetiaCustomerInfo.showAsBusinessCustomer"
          class="h-4 w-4 border-gray-300 text-[#1FAD96] focus:ring-[#1FAD96]"
        />
      </button>

      <button
        (click)="helvetiaCustomerInfo.showAsBusinessCustomer = true"
        [ngClass]="{
          'bg-[#F1FCF9] border-[#1FAD96]':
            helvetiaCustomerInfo.showAsBusinessCustomer,
          'bg-white border-gray-100':
            !helvetiaCustomerInfo.showAsBusinessCustomer
        }"
        class="h-32 w-40 text-center rounded-lg border flex flex-col items-center justify-center space-y-2"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="1"
          stroke-linecap="round"
          stroke-linejoin="round"
          class="lucide lucide-car"
        >
          <path
            d="M19 17h2c.6 0 1-.4 1-1v-3c0-.9-.7-1.7-1.5-1.9C18.7 10.6 16 10 16 10s-1.3-1.4-2.2-2.3c-.5-.4-1.1-.7-1.8-.7H5c-.6 0-1.1.4-1.4.9l-1.4 2.9A3.7 3.7 0 0 0 2 12v4c0 .6.4 1 1 1h2"
          />
          <circle cx="7" cy="17" r="2" />
          <path d="M9 17h6" />
          <circle cx="17" cy="17" r="2" />
        </svg>

        <p class="font-inter text-sm py-2">Erhverv</p>
        <input
          id="business"
          name="notification-method"
          type="radio"
          [checked]="helvetiaCustomerInfo.showAsBusinessCustomer"
          class="h-4 w-4 border-gray-300 text-[#1FAD96] focus:ring-[#1FAD96]"
        />
      </button>
    </div>

    <!-- ############# -->

    <div class="mt-2 space-y-4">
      <ng-container *ngIf="!helvetiaCustomerInfo.showAsBusinessCustomer">
        <div class="flex items-end">
          <div class="w-full">
            <app-input
              (keyup)="recalculateOnValidCpr()"
              class="component-container"
              [(ngModel)]="helvetiaCustomerInfo.cprNumber"
              [errorInInput]="errorInCprNumber"
              [title]="'CPR-Nummer'"
            ></app-input>
          </div>
          <div *ngIf="!disableCprLookup" class="ml-4">
            <button
              (click)="lookupCPR()"
              type="button"
              class="h-11 w-20 py-2 px-4 rounded-lg font-inter bg-[#072C29] whitespace-nowrap inline-flex justify-center items-center gap-x-2 text-sm border border-transparent text-white disabled:opacity-50 disabled:pointer-events-none"
            >
              <i *ngIf="cprLoading" class="fas fa-spinner fa-spin icon"></i>
              <p *ngIf="!cprLoading">Slå op</p>
            </button>
          </div>
        </div>

        <div class="flex gap-4">
          <div class="w-full">
            <app-input
              (ngModelChange)="this.errorInFirstname = false"
              class="component-container"
              [(ngModel)]="helvetiaCustomerInfo.firstname"
              [errorInInput]="errorInFirstname"
              [title]="'Navn'"
            ></app-input>
          </div>
          <div class="w-full">
            <app-input
              (ngModelChange)="this.errorInLastname = false"
              class="component-container"
              [(ngModel)]="helvetiaCustomerInfo.lastname"
              [errorInInput]="errorInLastname"
              [title]="'Efternavn'"
            ></app-input>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="helvetiaCustomerInfo.showAsBusinessCustomer">
        <div class="flex items-end">
          <div class="w-full">
            <app-input
              (ngModelChange)="this.errorInCvrNumber = false"
              class="component-container"
              [(ngModel)]="helvetiaCustomerInfo.cvrNumber"
              [errorInInput]="errorInCvrNumber"
              [title]="'CVR-Nummer'"
            ></app-input>
          </div>

          <div class="ml-4">
            <button
              (click)="lookupCVR()"
              type="button"
              class="h-11 w-20 py-2 px-4 rounded-lg font-inter bg-[#072C29] whitespace-nowrap inline-flex justify-center items-center gap-x-2 text-sm border border-transparent text-white disabled:opacity-50 disabled:pointer-events-none"
            >
              <i *ngIf="cvrLoading" class="fas fa-spinner fa-spin icon"></i>
              <p *ngIf="!cvrLoading">Slå op</p>
            </button>
          </div>
        </div>

        <div>
          <app-input
            (ngModelChange)="this.errorInCompanyName = false"
            class="component-container"
            [(ngModel)]="helvetiaCustomerInfo.companyName"
            [errorInInput]="errorInCompanyName"
            [title]="'Virksomhedsnavn'"
          ></app-input>
        </div>
      </ng-container>

      <div class="relative">
        <p class="font-inter text-[#042F2E] text-sm mb-1">Adresse</p>
        <input
          [ngClass]="{ 'border-red-500': this.errorInAddress }"
          [value]="
            helvetiaCustomerInfo.selectedAddressInfo.tekst
              ? helvetiaCustomerInfo.selectedAddressInfo.tekst
              : ''
          "
          (focus)="errorInAddress = false"
          (keyup)="autoCompleteAddress($event)"
          type="text"
          class="w-full justify-between border-gray-200 inline-flex items-center whitespace-nowrap py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border bg-white text-[#1F2937] shadow-sm hover:bg-gray-50 focus:border-[#1FAD96] focus:outline-none focus:ring-2 focus:ring-teal-500/25 disabled:opacity-50 disabled:pointer-events-none"
          autocomplete="new-password"
        />

        <div
          *ngIf="showAddressOption"
          class="absolute border font-semibold px-2 py-2 w-full z-50 mt-2 bg-white rounded-md shadow-lg fade-in-out max-h-96 overflow-y-auto"
        >
          <!-- Dropdown options -->
          <ul class="py-1">
            <li
              *ngFor="let address of topFiveAddresses"
              class="cursor-pointer px-2 py-2 rounded-md hover:bg-[#F3F4F6] font-inter text-sm"
              (click)="setAddress(address)"
            >
              {{ address.tekst }}
            </li>
          </ul>
        </div>
      </div>

      <div>
        <app-input
          (ngModelChange)="this.errorInEmail = false"
          class="component-container"
          [(ngModel)]="helvetiaCustomerInfo.email"
          [errorInInput]="errorInEmail"
          [title]="'Email'"
        ></app-input>
      </div>

      <div>
        <app-input
          (ngModelChange)="this.errorInPhoneNumber = false"
          class="component-container"
          [(ngModel)]="helvetiaCustomerInfo.phoneNumber"
          [errorInInput]="errorInPhoneNumber"
          [title]="'Telefon'"
        ></app-input>
      </div>
    </div>
  </div>
  <!-- End Section -->

  <!-- Section -->
  <div
    class="py-6 first:pt-0 last:pb-0 border-t first:border-transparent border-gray-200 dark:border-gray-700 dark:first:border-transparent"
  >
    <!-- #######################-->
    <!-- #######################-->
    <!-- #######################-->
    <!-- #######################-->

    <div *ngIf="helvetiaCustomerInfo.showAsBusinessCustomer">
      <div class="font-syne text-2xl font-semibold mb-5">Brugertype</div>

      <div class="relative">
        <span
          class="text-sm z-10 bg-white border shadow-lg rounded-md absolute bottom-full left-1/2 transform -translate-x-1/2 px-6 py-4 w-96"
          [class.hidden]="
            !showTooltip_privateExtraUser &&
            !showTooltip_companyExtraUser &&
            !showTooltip_noExtraUser
          "
        >
          <div *ngIf="showTooltip_privateExtraUser">
            <p class="font-medium mb-4">Primær bruger</p>
            <p class="mb-2 text-xs">
              Vælg primær bruger, hvis bilen eksempelvis er stillet til rådighed
              for en specifik person gennem en virksomhed, f.eks. via en
              firmabilsordning.
            </p>
          </div>

          <div *ngIf="showTooltip_companyExtraUser">
            <p class="font-medium mb-4">Virksomhed</p>
            <p class="mb-2 text-xs">
              Vælg virksomhed, hvis bilen står til rådighed for en anden
              virksomhed end den, der er angivet ovenfor. Bemærk, at
              forsikringssummen kan ændre sig i dette tilfælde.
            </p>
          </div>

          <div *ngIf="showTooltip_noExtraUser">
            <p class="font-medium mb-4">Fælles</p>
            <p class="mb-2 text-xs">
              Vælg fælles, hvis flere personer i virksomheden deler køretøjet
              uden en fast defineret bruger. Bemærk, at forsikringssummen kan
              ændre sig i dette tilfælde.
            </p>
          </div>
        </span>
        <ul class="flex flex-col sm:flex-row font-syne my-5">
          <li
            [ngClass]="{ 'border-red-500': this.errorInExtraUserType }"
            class="inline-flex items-center gap-x-2.5 py-3 px-4 text-sm font-medium bg-white border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg sm:-ms-px sm:mt-0 sm:first:rounded-se-none sm:first:rounded-es-lg sm:last:rounded-es-none sm:last:rounded-se-lg dark:bg-neutral-800 dark:border-neutral-700 dark:text-white"
          >
            <div
              class="relative flex items-start w-full"
              (mouseover)="showTooltip_privateExtraUser = true"
              (mouseout)="showTooltip_privateExtraUser = false"
            >
              <div class="flex items-center h-5">
                <input
                  [(ngModel)]="helvetiaCustomerInfo.extraUserType"
                  (ngModelChange)="recalculateHelvetiaPrices()"
                  value="private"
                  id="hs-horizontal-list-group-item-radio-1"
                  name="hs-horizontal-list-group-item-radio"
                  type="radio"
                  class="focus:ring-1 checked:bg-[#34C0A7] text-[#34C0A7]"
                />
              </div>
              <label
                for="hs-horizontal-list-group-item-radio-1"
                class="ms-3 block w-full text-sm text-gray-600 dark:text-neutral-500"
              >
                Primær bruger
              </label>
            </div>
          </li>

          <li
            [ngClass]="{ 'border-red-500': this.errorInExtraUserType }"
            class="inline-flex items-center gap-x-2.5 py-3 px-4 text-sm font-medium bg-white border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg sm:-ms-px sm:mt-0 sm:first:rounded-se-none sm:first:rounded-es-lg sm:last:rounded-es-none sm:last:rounded-se-lg dark:bg-neutral-800 dark:border-neutral-700 dark:text-white"
          >
            <div
              class="relative flex items-start w-full"
              (mouseover)="showTooltip_companyExtraUser = true"
              (mouseout)="showTooltip_companyExtraUser = false"
            >
              <div class="flex items-center h-5">
                <input
                  [(ngModel)]="helvetiaCustomerInfo.extraUserType"
                  (ngModelChange)="recalculateHelvetiaPrices()"
                  value="business"
                  id="hs-horizontal-list-group-item-radio-2"
                  name="hs-horizontal-list-group-item-radio"
                  type="radio"
                  class="focus:ring-1 checked:bg-[#34C0A7] text-[#34C0A7]"
                />
              </div>
              <label
                for="hs-horizontal-list-group-item-radio-2"
                class="ms-3 block w-full text-sm text-gray-600 dark:text-neutral-500"
              >
                Virksomhed
              </label>
            </div>
          </li>

          <li
            [ngClass]="{ 'border-red-500': this.errorInExtraUserType }"
            class="inline-flex items-center gap-x-2.5 py-3 px-4 text-sm font-medium bg-white border text-gray-800 -mt-px first:rounded-t-lg first:mt-0 last:rounded-b-lg sm:-ms-px sm:mt-0 sm:first:rounded-se-none sm:first:rounded-es-lg sm:last:rounded-es-none sm:last:rounded-se-lg dark:bg-neutral-800 dark:border-neutral-700 dark:text-white"
          >
            <div
              class="relative flex items-start w-full"
              (mouseover)="showTooltip_noExtraUser = true"
              (mouseout)="showTooltip_noExtraUser = false"
            >
              <div class="flex items-center h-5">
                <input
                  [(ngModel)]="helvetiaCustomerInfo.extraUserType"
                  (ngModelChange)="recalculateHelvetiaPrices()"
                  value="none"
                  id="hs-horizontal-list-group-item-radio-3"
                  name="hs-horizontal-list-group-item-radio"
                  type="radio"
                  class="focus:ring-1 checked:bg-[#34C0A7] text-[#34C0A7]"
                />
              </div>
              <label
                for="hs-horizontal-list-group-item-radio-3"
                class="ms-3 block w-full text-sm text-gray-600 dark:text-neutral-500"
              >
                Fælles
              </label>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <!-- #######################-->
    <!-- #######################-->

    <div
      class="space-y-4"
      *ngIf="
        helvetiaCustomerInfo.extraUserType == 'private' &&
        helvetiaCustomerInfo.showAsBusinessCustomer
      "
    >
      <div class="flex items-end">
        <div class="w-full">
          <app-input
            (ngModelChange)="this.errorInExtraUserCprNumber = false"
            class="component-container"
            [(ngModel)]="helvetiaCustomerInfo.extraUserCprNumber"
            [errorInInput]="errorInExtraUserCprNumber"
            [title]="'CPR-Nummer'"
          ></app-input>
        </div>

        <div *ngIf="!disableCprLookup" class="ml-4">
          <button
            (click)="lookupExtraUserCPR()"
            type="button"
            class="h-11 w-20 py-2 px-4 rounded-lg font-inter bg-[#072C29] whitespace-nowrap inline-flex justify-center items-center gap-x-2 text-sm border border-transparent text-white disabled:opacity-50 disabled:pointer-events-none"
          >
            <i
              *ngIf="extraUserCprLoading"
              class="fas fa-spinner fa-spin icon"
            ></i>
            <p *ngIf="!extraUserCprLoading">Slå op</p>
          </button>
        </div>
      </div>

      <div class="flex gap-4">
        <div class="w-full">
          <app-input
            class="component-container"
            (ngModelChange)="errorInExtraUserFirstname = false"
            [errorInInput]="errorInExtraUserFirstname"
            [(ngModel)]="helvetiaCustomerInfo.extraUserFirstname"
            [title]="'Navn'"
          ></app-input>
        </div>

        <div class="w-full">
          <app-input
            class="component-container"
            (ngModelChange)="errorInExtraUserLastname = false"
            [errorInInput]="errorInExtraUserLastname"
            [(ngModel)]="helvetiaCustomerInfo.extraUserLastname"
            [title]="'Efternavn'"
          ></app-input>
        </div>
      </div>

      <div class="relative">
        <p class="font-inter text-[#042F2E] text-sm mb-1">Adresse</p>
        <input
          [ngClass]="{ 'border-red-500': this.errorInExtraUserAddress }"
          [value]="
            helvetiaCustomerInfo.extraUserSelectedAddressInfo.tekst
              ? helvetiaCustomerInfo.extraUserSelectedAddressInfo.tekst
              : ''
          "
          (focus)="errorInExtraUserAddress = false"
          (keyup)="autoCompleteExtraUserAddress($event)"
          type="text"
          class="w-full justify-between border-gray-200 inline-flex items-center whitespace-nowrap py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border bg-white text-[#1F2937] shadow-sm hover:bg-gray-50 focus:border-[#1FAD96] focus:outline-none focus:ring-2 focus:ring-teal-500/25 disabled:opacity-50 disabled:pointer-events-none"
          autocomplete="new-password"
        />

        <div
          *ngIf="showExtraUserAddressOption"
          class="absolute border font-semibold px-2 py-2 w-full z-50 mt-2 bg-white rounded-md shadow-lg fade-in-out max-h-96 overflow-y-auto"
        >
          <!-- Dropdown options -->
          <ul class="py-1">
            <li
              *ngFor="let address of topFiveExtraUserAddresses"
              class="cursor-pointer px-2 py-2 rounded-md hover:bg-[#F3F4F6] font-inter text-sm"
              (click)="setExtraUserAddressAddress(address)"
            >
              {{ address.tekst }}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div
      class="space-y-4"
      *ngIf="
        helvetiaCustomerInfo.extraUserType == 'business' &&
        helvetiaCustomerInfo.showAsBusinessCustomer
      "
    >
      <div class="flex items-end">
        <div class="w-full">
          <app-input
            (ngModelChange)="this.errorInExtraUserCvrNumber = false"
            class="component-container"
            [(ngModel)]="helvetiaCustomerInfo.extraUserCvrNumber"
            [errorInInput]="errorInExtraUserCvrNumber"
            [title]="'CVR-Nummer'"
          ></app-input>
        </div>

        <div class="ml-4">
          <button
            (click)="lookupExtraUserCVR()"
            type="button"
            class="h-11 w-20 py-2 px-4 rounded-lg font-inter bg-[#072C29] whitespace-nowrap inline-flex justify-center items-center gap-x-2 text-sm border border-transparent text-white disabled:opacity-50 disabled:pointer-events-none"
          >
            <i
              *ngIf="extraUserCvrLoading"
              class="fas fa-spinner fa-spin icon"
            ></i>
            <p *ngIf="!extraUserCvrLoading">Slå op</p>
          </button>
        </div>
      </div>

      <div>
        <app-input
          (ngModelChange)="this.errorInExtraUserCompanyName = false"
          class="component-container"
          [(ngModel)]="helvetiaCustomerInfo.extraUserCompanyName"
          [errorInInput]="errorInExtraUserCompanyName"
          [title]="'Virksomhedsnavn'"
        ></app-input>
      </div>

      <div class="relative">
        <p class="font-inter text-[#042F2E] text-sm mb-1">Adresse</p>
        <input
          [ngClass]="{ 'border-red-500': this.errorInExtraUserAddress }"
          [value]="
            helvetiaCustomerInfo.extraUserSelectedAddressInfo.tekst
              ? helvetiaCustomerInfo.extraUserSelectedAddressInfo.tekst
              : ''
          "
          (focus)="errorInExtraUserAddress = false"
          (keyup)="autoCompleteExtraUserAddress($event)"
          type="text"
          class="w-full justify-between border-gray-200 inline-flex items-center whitespace-nowrap py-3 px-4 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border bg-white text-[#1F2937] shadow-sm hover:bg-gray-50 focus:border-[#1FAD96] focus:outline-none focus:ring-2 focus:ring-teal-500/25 disabled:opacity-50 disabled:pointer-events-none"
          autocomplete="new-password"
        />

        <div
          *ngIf="showExtraUserAddressOption"
          class="absolute border font-semibold px-2 py-2 w-full z-50 mt-2 bg-white rounded-md shadow-lg fade-in-out max-h-96 overflow-y-auto"
        >
          <!-- Dropdown options -->
          <ul class="py-1">
            <li
              *ngFor="let address of topFiveExtraUserAddresses"
              class="cursor-pointer px-2 py-2 rounded-md hover:bg-[#F3F4F6] font-inter text-sm"
              (click)="setExtraUserAddressAddress(address)"
            >
              {{ address.tekst }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- #######################-->
    <!-- #######################-->
    <!-- Section -->

    <div *ngIf="!helvetiaCustomerInfo.showAsBusinessCustomer">
      <div class="font-syne text-2xl font-semibold mb-5">Ekstra fører</div>

      <div class="mt-2 space-y-4">
        <div class="grid gap-4">
          <div class="flex items-end">
            <div class="w-full">
              <app-input
                (keyup)="recalculateOnValidCprExtraUser()"
                (ngModelChange)="this.errorInExtraUserCprNumber = false"
                class="component-container"
                [(ngModel)]="helvetiaCustomerInfo.extraUserCprNumber"
                [errorInInput]="errorInExtraUserCprNumber"
                [title]="'CPR-Nummer'"
              ></app-input>
            </div>

            <div *ngIf="!disableCprLookup" class="ml-4">
              <button
                (click)="lookupExtraUserCPR()"
                type="button"
                class="h-11 w-20 py-2 px-4 rounded-lg font-inter bg-[#072C29] whitespace-nowrap inline-flex justify-center items-center gap-x-2 text-sm border border-transparent text-white disabled:opacity-50 disabled:pointer-events-none"
              >
                <i
                  *ngIf="extraUserCprLoading"
                  class="fas fa-spinner fa-spin icon"
                ></i>
                <p *ngIf="!extraUserCprLoading">Slå op</p>
              </button>
            </div>
          </div>

          <div class="flex gap-4">
            <div class="w-full">
              <app-input
                (ngModelChange)="this.errorInExtraUserFirstname = false"
                class="component-container"
                [(ngModel)]="helvetiaCustomerInfo.extraUserFirstname"
                [errorInInput]="errorInExtraUserFirstname"
                [title]="'Navn'"
              ></app-input>
            </div>
            <div class="w-full">
              <app-input
                (ngModelChange)="this.errorInExtraUserLastname = false"
                class="component-container"
                [(ngModel)]="helvetiaCustomerInfo.extraUserLastname"
                [errorInInput]="errorInExtraUserLastname"
                [title]="'Efternavn'"
              ></app-input>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- End Section -->
  </div>
</div>

<button
  *ngIf="!loadinginsuranceInfo"
  (click)="validateCustomerInfo()"
  type="button"
  class="py-3 px-4 shadow-lg font-inter inline-flex items-center gap-x-1 text-sm rounded-full border border-transparent bg-[#34C0A7] text-white hover:bg-teal-500 disabled:opacity-50 disabled:pointer-events-none"
>
  Næste
</button>

<div *ngIf="loadinginsuranceInfo">
  <svg
    aria-hidden="true"
    class="w-10 h-10 text-gray-100 animate-spin fill-[#1FAD96]"
    viewBox="0 0 100 101"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
      fill="currentColor"
    />
    <path
      d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
      fill="currentFill"
    />
  </svg>
</div>
