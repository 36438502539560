<div>
  <div class="mb-6">
    <div class="font-syne text-2xl font-semibold mb-5">
      Forventede årlige kilometer
    </div>
    <div class="w-56">
      <app-dropdown
        [errorInDropdown]="errorInSelectedAnnualMileage"
        [disableDropdown]="loadinginsuranceInfo"
        [selectedValue]="selectedAnnualMileage.name"
        [title]="'Kilometer'"
        [options]="dropdown_expectedAnnualMileage"
        (output)="dropdownOutput($event, 'annualMileage')"
      ></app-dropdown>
    </div>

    <div *ngIf="loadinginsuranceInfo && !annualMileagePriceUpdate">
      <svg
        aria-hidden="true"
        class="w-10 h-10 text-gray-100 animate-spin fill-[#1FAD96] m-5"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
    </div>
  </div>

  <!-- If selectedAnnualMileage is selected -->
  <div
    *ngIf="selectedAnnualMileage.value !== -1 && annualMileagePriceUpdate"
    [@fadeInOutStep]
  >
    <div class="mb-6">
      <div class="flex mb-5">
        <div class="font-syne text-2xl font-semibold">
          Antal skader inden for de seneste 3 år
        </div>

        <span class="flex items-center">
          <div class="relative">
            <i
              (mouseover)="showTooltip_GlassCover = true"
              (mouseout)="showTooltip_GlassCover = false"
              class="fas fa-regular fa-circle-info text-gray-300 ml-4"
            ></i>
            <span
              class="text-sm z-10 bg-white border shadow-lg rounded-md absolute bottom-full left-1/2 transform -translate-x-1/2 px-6 py-4 w-96"
              [class.hidden]="!showTooltip_GlassCover"
            >
              <p class="font-medium mb-4">
                Hvor mange skader har du anmeldt på din ansvarsforsikring til
                din bil inden for de seneste 3 år?
              </p>
              <p class="mb-2 text-xs">
                Med skade menes enhver hændelse med din bil inden for de seneste
                3 år, hvor en tredjemand er blevet påført en skade som følge af
                din uagtsomhed.
              </p>
            </span>
          </div>
        </span>
      </div>

      <div class="w-56">
        <app-dropdown
          [errorInDropdown]="errorInSelectedNumberOfDamages"
          [selectedValue]="selectedNumberOfDamages.name"
          [disableDropdown]="loadinginsuranceInfo"
          [title]="'Antal skader'"
          [options]="dropdown_numberOfDamages"
          (output)="dropdownOutput($event, 'numberOfDamages')"
        ></app-dropdown>
      </div>
    </div>
    <!-- If selectedAnnualMileage is selected -->
    <div *ngIf="selectedNumberOfDamages.value !== -1" [@fadeInOutStep]>
      <button
        *ngIf="!loadinginsuranceInfo"
        (click)="validateHelvetiaExtraInfo()"
        type="button"
        class="py-3 px-4 shadow-lg font-inter inline-flex items-center gap-x-1 text-sm rounded-full border border-transparent bg-[#34C0A7] text-white hover:bg-teal-500 disabled:opacity-50 disabled:pointer-events-none"
      >
        Næste
      </button>
    </div>

    <div *ngIf="loadinginsuranceInfo">
      <svg
        aria-hidden="true"
        class="w-10 h-10 text-gray-100 animate-spin fill-[#1FAD96] m-5"
        viewBox="0 0 100 101"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
          fill="currentColor"
        />
        <path
          d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
          fill="currentFill"
        />
      </svg>
    </div>
  </div>
</div>
<!-- End Col -->

<div></div>
