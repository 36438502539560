import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-helvetia-side-display',
  templateUrl: './helvetia-side-display.component.html',
  styleUrls: ['./helvetia-side-display.component.scss'],
})
export class HelvetiaSideDisplayComponent {
  @Output() togglePriceIntervalEvent = new EventEmitter();

  @Input() showYearlyPrice: boolean = false;
  @Input() loadinginsuranceInfo: boolean = false;
  @Input() helvetiaMonthlyPrice: number = 0;
  @Input() helvetiaYearlyPrice: number = 0;

  togglePriceInterval() {
    this.togglePriceIntervalEvent.emit();
  }
}
