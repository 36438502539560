import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { LoginService } from '../login/login.service';
import { Router } from '@angular/router';
import { environment } from 'projects/shared/src/environments/environment';
import { ApiService } from '../api-service/api.service';

@Injectable({
  providedIn: 'root',
})
export class CvrService {
  constructor(
    private router: Router,
    private loginService: LoginService,
    private apiService: ApiService
  ) {}

  /**
   * Looks up company information using the provided CVR number.
   *
   * @param {string} cvr - The CVR number of the company to be looked up.
   * @param {boolean} [requireAuth=true] - Whether authentication is required for the request.
   * @returns {Observable<any>} - An Observable that emits the company information
   * or an error if the request fails.
   */
  lookupCVR(cvr: string, requireAuth: boolean = true): Observable<any> {
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    if (requireAuth) {
      const accessToken = this.loginService.getActiveToken();
      const refreshToken = this.loginService.getRefreshToken();

      if (!accessToken || !refreshToken) {
        console.error('Error with tokens in lookupCVR');
        this.loginService.clearTokens();
        this.router.navigate(['/login']);
        return of(null);
      }

      const isExpired = this.loginService.isTokenExpired(accessToken);

      if (isExpired) {
        return this.loginService.refreshAccessToken(refreshToken).pipe(
          switchMap(() => {
            headers = headers.set('Authorization', `Bearer ${accessToken}`);
            return this.apiService.makeApiCall<any>(
              `${environment.apiGetCompanyInfoFromCVR}${cvr}`,
              headers,
              'GET'
            );
          }),
          catchError((error) => {
            console.error('Error refreshing token', error);
            this.loginService.clearTokens();
            this.router.navigate(['/login']);
            return throwError(() => error);
          })
        );
      }

      headers = headers.set('Authorization', `Bearer ${accessToken}`);
    }

    return this.apiService.makeApiCall<any>(
      `${environment.apiGetCompanyInfoFromCVR}${cvr}`,
      headers,
      'GET'
    );
  }

  /**
   * Retrieves company information along with its subsidiaries using the provided CVR number.
   *
   * @param {string} cvr - The CVR number of the company to be looked up, including subsidiaries.
   * @returns {Observable<any>} - An Observable that emits the company and subsidiary information
   * or an error if the request fails.
   */
  companyWithSubsidiary(cvr: string): Observable<any> {
    const accessToken = this.loginService.getActiveToken();
    const refreshToken = this.loginService.getRefreshToken();

    if (!accessToken || !refreshToken) {
      console.error('Error with tokens in companyWithSubsidiary');
      this.loginService.clearTokens();
      this.router.navigate(['/login']);
      return of(null);
    }

    const isExpired = this.loginService.isTokenExpired(accessToken);

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`,
    });

    if (isExpired) {
      return this.loginService.refreshAccessToken(refreshToken).pipe(
        switchMap(() => {
          return this.apiService.makeApiCall<any>(
            `${environment.apiGetCompanyWithSubsidiaryFromCVR}${cvr}`,
            headers,
            'GET'
          );
        }),
        catchError((error) => {
          console.error('Error refreshing token', error);
          this.loginService.clearTokens();
          this.router.navigate(['/login']);
          return throwError(() => error);
        })
      );
    }

    return this.apiService.makeApiCall<any>(
      `${environment.apiGetCompanyWithSubsidiaryFromCVR}${cvr}`,
      headers,
      'GET'
    );
  }
}
