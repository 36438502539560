import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DmrResponse, DmrService } from 'projects/shared/src/public-api';

@Component({
  selector: 'lib-vehicle-info',
  templateUrl: './vehicle-info.component.html',
  styleUrls: ['./vehicle-info.component.scss'],
})
export class VehicleInfoComponent {
  // Event emitted to the parent component
  @Output() setVehicleFromDmr = new EventEmitter<DmrResponse>();
  @Output() setregOrVinNumber = new EventEmitter<string>();
  @Output() nextStepEvent = new EventEmitter();
  @Output() resetEvent = new EventEmitter();

  // Input properties to receive data from the parent component
  @Input() loadingButton: boolean = false;
  @Input() label: string = '';

  vehicleFromDmr: DmrResponse = {} as DmrResponse;
  regOrVinNumber: string = '';

  errorInRegOrVinNumber: boolean = false;
  receivedDMRVehicle: boolean = false;
  regOrVinNumberInputFocused: boolean = false;
  loadingDmrScrape: boolean = false;
  specialCarError: boolean = false;
  loadingRegOrVinNumber: boolean = false;

  constructor(private dmrService: DmrService) {}

  regOrVinNumberChange(): void {
    this.receivedDMRVehicle = false;
    this.setregOrVinNumber.emit(this.regOrVinNumber);
  }

  /**
   * Scraping request for vehicle information based on the reg or VIN number.
   * @returns void
   */
  dmrScrape(): void {
    this.loadingDmrScrape = true;

    if (this.regOrVinNumber.length === 7) {
      // Handle registration number
      this.dmrService.scrapeVehicleByRegNumber(this.regOrVinNumber).subscribe({
        next: (result: any) => {
          this.loadingDmrScrape = false;
          this.vehicleFromDmr = result.vehicle;
          this.setVehicleFromDmr.emit(this.vehicleFromDmr);
          if (this.checkIfSpecialCarsBrand(this.vehicleFromDmr.make)) {
            this.receivedDMRVehicle = false;
            this.specialCarError = true;
          }
        },
        error: (error) => {
          this.loadingDmrScrape = false;
          console.error(error);
        },
      });
    } else {
      // Handle VIN number
      this.dmrService.scrapeVehicleByVINNumber(this.regOrVinNumber).subscribe({
        next: (result: any) => {
          this.loadingDmrScrape = false;
          this.vehicleFromDmr = result.vehicleInfo;
          this.setVehicleFromDmr.emit(this.vehicleFromDmr);
          if (this.checkIfSpecialCarsBrand(this.vehicleFromDmr.make)) {
            this.receivedDMRVehicle = false;
            this.specialCarError = true;
          }
        },
        error: (error) => {
          this.loadingDmrScrape = false;
          console.error(error);
        },
      });
    }
  }

  /**
   * Get vehicle information from dmrService based on reg or VIN number
   * @returns void
   */
  dmrInfo(): void {
    // Reset all relevant information when this method is called.
    // This is useful if the user has already searched for a car, entered basic information, and then decided to go back.
    this.resetEvent.emit();
    this.setregOrVinNumber.emit(this.regOrVinNumber);

    this.receivedDMRVehicle = false;
    this.specialCarError = false;
    this.errorInRegOrVinNumber = false;
    this.regOrVinNumber = this.regOrVinNumber.replace(/\s/g, '');

    // Handle registration number
    if (this.regOrVinNumber.trim().length < 10) {
      this.loadingRegOrVinNumber = true;
      this.dmrService.getVehicleByRegNumber(this.regOrVinNumber).subscribe({
        next: (result: any) => {
          this.vehicleFromDmr = result.vehicle;
          this.setVehicleFromDmr.emit(this.vehicleFromDmr);
          this.loadingRegOrVinNumber = false;
          if (this.checkIfSpecialCarsBrand(this.vehicleFromDmr.make)) {
            this.receivedDMRVehicle = false;
            this.specialCarError = true;
          } else {
            this.receivedDMRVehicle = true;
          }
        },
        error: (error) => {
          this.errorInRegOrVinNumber = true;
          this.loadingRegOrVinNumber = false;
          this.receivedDMRVehicle = false;

          console.error(error);
          return;
        },
      });
    } else {
      // Handle VIN number
      this.loadingRegOrVinNumber = true;
      this.dmrService.getVehicleByVINNumber(this.regOrVinNumber).subscribe({
        next: (result: any) => {
          this.vehicleFromDmr = result.vehicleInfo;
          this.loadingRegOrVinNumber = false;
          this.setVehicleFromDmr.emit(this.vehicleFromDmr);
          if (this.checkIfSpecialCarsBrand(this.vehicleFromDmr.make)) {
            this.receivedDMRVehicle = false;
            this.specialCarError = true;
          } else {
            this.receivedDMRVehicle = true;
          }
        },
        error: (error) => {
          this.errorInRegOrVinNumber = true;
          this.loadingRegOrVinNumber = false;
          this.receivedDMRVehicle = false;

          console.error(error);
          return;
        },
      });
    }
  }

  /**
   * Checks if a given car brand is considered a special brand.
   * @param brand - The car brand name to check.
   * @returns boolean - Returns `true` if the brand is in the special car brands list, otherwise `false`.
   */
  checkIfSpecialCarsBrand(brand: string): boolean {
    const specialCarsBrands = [
      'ASTON MARTIN',
      'ALPINA',
      'BENTLEY',
      'BUGATTI',
      'HUMMER',
      'LAMBORGHINI',
      'MCLAREN',
      'ROLLS-ROYCE',
      'DE TOMASO',
      'KOENIGSEGG',
      'PAGANI',
      'ZENVO',
      'RUF',
      'RIMAC',
    ];

    return specialCarsBrands.includes(brand);
  }

  /**
   * Emit event to move to the next step
   * @returns void
   */
  nextStep(): void {
    this.nextStepEvent.emit();
  }
}
