import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HelvetiaVariant } from '../../models/helvetia.model';
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from '@angular/animations';

@Component({
  selector: 'lib-helvetia-info',
  templateUrl: './helvetia-info.component.html',
  styleUrls: ['./helvetia-info.component.scss'],
  animations: [
    // Animation for show steps
    trigger('fadeInOutStep', [
      state(
        'void',
        style({
          opacity: 0,
        })
      ),
      transition('void <=> *', animate(250)),
    ]),
  ],
})
export class HelvetiaInfoComponent {
  @Input() loadinginsuranceInfo: boolean = false;
  @Input() helvetiaVariants: HelvetiaVariant[] = [];
  @Input() annualMileagePriceUpdate: boolean = false;

  @Output() nextStepEvent = new EventEmitter();
  @Output() recalculateHelvetiaPrices = new EventEmitter();
  @Output() setSelectedNumberOfDamages = new EventEmitter<{
    name: string;
    value: number;
  }>();
  @Output() setSelectedAnnualMileage = new EventEmitter<{
    name: string;
    value: number;
  }>();

  @Input() selectedNumberOfDamages: { name: string; value: number } = {
    name: '',
    value: -1,
  };
  @Input() selectedAnnualMileage: { name: string; value: number } = {
    name: '',
    value: -1,
  };

  errorInSelectedAnnualMileage: boolean = false;
  errorInSelectedNumberOfDamages: boolean = false;

  dropdown_expectedAnnualMileage = [
    { name: '0 - 9.999 km pr. år', value: 9999 },
    { name: '10.000 - 14.999 km pr. år', value: 14999 },
    { name: '15.000 - 19.999 km pr. år', value: 19999 },
    { name: '20.000 - 24.999 km pr. år', value: 24999 },
    { name: '25.000 - 29.999 km pr. år', value: 29999 },
    { name: 'Over 30.000 km pr. år', value: 30000 },
  ];

  dropdown_numberOfDamages = [
    { name: '0 skader', value: 0 },
    { name: '1 skade', value: 1 },
    { name: '2 skader', value: 2 },
    { name: '3 skader', value: 3 },
    { name: 'Mere end 3 skader', value: 4 },
  ];

  showTooltip_GlassCover: boolean = false;

  constructor() {}

  dropdownOutput(item: any, dropdownId: string) {
    if (dropdownId == 'annualMileage') {
      this.setSelectedAnnualMileage.emit(item);
      this.selectedAnnualMileage = item;
      this.recalculateHelvetiaPrices.emit();
    }

    if (dropdownId == 'numberOfDamages') {
      this.selectedNumberOfDamages = item;
      this.setSelectedNumberOfDamages.emit(item);
      this.recalculateHelvetiaPrices.emit();
    }
  }

  validateHelvetiaExtraInfo() {
    this.errorInSelectedAnnualMileage = false;
    this.errorInSelectedNumberOfDamages = false;

    if (
      this.selectedAnnualMileage.value === -1 ||
      this.selectedNumberOfDamages.value === -1
    ) {
      this.errorInSelectedAnnualMileage =
        this.selectedAnnualMileage.value === -1;
      this.errorInSelectedNumberOfDamages =
        this.selectedNumberOfDamages.value === -1;
      return;
    }

    this.nextStepEvent.emit();
  }
}
