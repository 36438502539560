import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent {
  @Output() resetEvent = new EventEmitter();
  @Output() previousStepEvent = new EventEmitter();

  @Input() activeStepIndex = 0;
  @Input() numberOfSteps = 0;

  constructor() {}

  calculateWidth(): string {
    if (this.numberOfSteps === 0) {
      return '0%';
    }
    return `${((this.activeStepIndex + 1) / this.numberOfSteps) * 100}%`;
  }

  previousStep() {
    this.previousStepEvent.emit();
  }

  restart() {
    this.resetEvent.emit();
  }
}
