import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'lib-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.css'],
})
export class NotificationDialogComponent {
  type: string = ''; // error, success, warning, etc.
  title: string = '';
  message: string = '';

  constructor(
    public dialogRef: MatDialogRef<NotificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.type = data.type;
    this.message = data.message;
    this.title = data.title;
  }

  confirm() {
    this.dialogRef.close('confirmed');
  }

  cancel() {
    this.dialogRef.close('cancel');
  }
}
