import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DavaService } from '../../services/dava/dava.service';
import { DavaAddressInfo } from '../../models/dava.model';
import { helvetiaCustomerInfo } from '../../models/helvetia.model';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'lib-helvetia-customer-info',
  templateUrl: './helvetia-customer-info.component.html',
  styleUrls: ['./helvetia-customer-info.component.scss'],
})
export class HelvetiaCustomerInfoComponent {
  @Output() recalculateHelvetiaPricesEvent = new EventEmitter();
  @Output() nextStepEvent = new EventEmitter();
  @Output() cprLookupEvent = new EventEmitter();
  @Output() cvrLookupEvent = new EventEmitter();
  @Output() setCustomerInfoEvent = new EventEmitter<helvetiaCustomerInfo>();
  @Output() cprExtraUserLookupEvent = new EventEmitter();
  @Output() cvrExtraUserLookupEvent = new EventEmitter();
  @Output() newAgeBasedOnCprEvent = new EventEmitter<number>();
  @Output() newAgeBasedOnExtraUserCprEvent = new EventEmitter<number>();

  @Input() cvrLoading: boolean = false;
  @Input() errorInCvrNumber: boolean = false;
  @Input() showAddressOption: boolean = false;
  @Input() errorInCprNumber: boolean = false;
  @Input() cprLoading: boolean = false;

  @Input() extraUserCvrLoading: boolean = false;
  @Input() errorInExtraUserCompanyName: boolean = false;
  @Input() errorInExtraUserCvrNumber: boolean = false;
  @Input() extraUserCprLoading: boolean = false;
  @Input() errorInExtraUserCprNumber: boolean = false;
  @Input() showExtraUserAddressOption: boolean = false;

  @Input() loadinginsuranceInfo: boolean = false;
  @Input() disableCprLookup: boolean = false;
  @Input() textDirectedToCustomer: boolean = false;
  @Input() userId: number = 0;
  @Input() helvetiaCustomerInfo: helvetiaCustomerInfo = {
    showAsBusinessCustomer: false,
    cprNumber: '',
    cvrNumber: '',
    firstname: '',
    lastname: '',
    companyName: '',
    selectedAddressInfo: {} as DavaAddressInfo,
    email: '',
    phoneNumber: '',
    extraUserType: '',
    extraUserCvrNumber: '',
    extraUserCompanyName: '',
    extraUserCprNumber: '',
    extraUserFirstname: '',
    extraUserLastname: '',
    extraUserSelectedAddressInfo: {} as DavaAddressInfo,
  };

  topFiveAddresses: DavaAddressInfo[] = [];
  topFiveExtraUserAddresses: DavaAddressInfo[] = [];

  errorInFirstname: boolean = false;
  errorInLastname: boolean = false;
  errorInCompanyName: boolean = false;
  errorInAddress: boolean = false;
  errorInEmail: boolean = false;
  errorInPhoneNumber: boolean = false;
  errorInExtraUserType: boolean = false; // error if extraUserType= ''
  errorInExtraUserLastname: boolean = false;
  errorInExtraUserFirstname: boolean = false;
  errorInExtraUserAddress: boolean = false;

  showTooltip_privateExtraUser: boolean = false;
  showTooltip_companyExtraUser: boolean = false;
  showTooltip_noExtraUser: boolean = false;

  newAgeBasedOnCprSubject = new Subject<number>();
  newAgeBasedOnExtraUserCprSubject = new Subject<number>();

  cprDay: number = 0;
  cprMonth: number = 0;
  cprYear: number = 0;

  constructor(private davaService: DavaService) {}

  ngOnInit() {
    this.newAgeBasedOnCprSubject.pipe(debounceTime(500)).subscribe((value) => {
      this.newAgeBasedOnCprEvent.emit(value);
    });

    this.newAgeBasedOnExtraUserCprSubject
      .pipe(debounceTime(500))
      .subscribe((value) => {
        this.newAgeBasedOnExtraUserCprEvent.emit(value);
      });
  }

  recalculateHelvetiaPrices() {
    this.setCustomerInfoEvent.emit(this.helvetiaCustomerInfo);
    this.recalculateHelvetiaPricesEvent.emit();
  }

  toggleShowAsBusinessCustomer() {
    this.resetCustomerInfoErrorFlags();

    this.setCustomerInfoEvent.emit(this.helvetiaCustomerInfo);
    this.recalculateHelvetiaPricesEvent.emit();
  }

  lookupCPR() {
    if (this.helvetiaCustomerInfo.cprNumber.trim().length == 0) {
      this.errorInCprNumber = true;
      return;
    }
    this.cprLookupEvent.emit();
  }

  lookupCVR() {
    if (this.helvetiaCustomerInfo.cvrNumber.trim().length == 0) {
      this.errorInCvrNumber = true;
      return;
    }
    this.cvrLookupEvent.emit();
  }

  lookupExtraUserCPR() {
    if (this.helvetiaCustomerInfo.extraUserCprNumber.trim().length == 0) {
      return;
    }
    this.cprExtraUserLookupEvent.emit();
  }

  lookupExtraUserCVR() {
    if (this.helvetiaCustomerInfo.extraUserCvrNumber.trim().length == 0) {
      return;
    }

    this.cvrExtraUserLookupEvent.emit();
  }

  autoCompleteAddress(event: Event) {
    // reset values if the adress input is changed.
    this.helvetiaCustomerInfo.selectedAddressInfo = {} as DavaAddressInfo;

    const inputElement = event.target as HTMLInputElement;
    const address = inputElement.value;

    // Perform the autocomplete
    this.davaService.davaAutoComplete(address).subscribe({
      next: (result: DavaAddressInfo[]) => {
        this.showAddressOption = true;
        this.topFiveAddresses = result.slice(0, 5);
      },
      error: (error) => {
        this.showAddressOption = false;
        console.error(error);
        return;
      },
    });
  }

  autoCompleteExtraUserAddress(event: Event) {
    // reset values if the adress input is changed.
    this.helvetiaCustomerInfo.extraUserSelectedAddressInfo =
      {} as DavaAddressInfo;

    const inputElement = event.target as HTMLInputElement;
    const address = inputElement.value;

    // Perform the autocomplete
    this.davaService.davaAutoComplete(address).subscribe({
      next: (result: DavaAddressInfo[]) => {
        this.showExtraUserAddressOption = true;
        this.topFiveExtraUserAddresses = result.slice(0, 5);
      },
      error: (error) => {
        this.showExtraUserAddressOption = false;
        console.error(error);
        return;
      },
    });
  }

  getAgeBasedOnCpr() {
    const currentDate = new Date();

    const yearSubstring = parseInt(
      currentDate.getFullYear().toString().substring(2),
    );
    const fullYear =
      this.cprYear < yearSubstring ? this.cprYear + 2000 : this.cprYear + 1900;

    const cprDate = new Date(fullYear, this.cprMonth - 1, this.cprDay);

    const year1 = cprDate.getFullYear();
    const month1 = cprDate.getMonth();
    const day1 = cprDate.getDate();

    const year2 = currentDate.getFullYear();
    const month2 = currentDate.getMonth();
    const day2 = currentDate.getDate();

    let yearDifference = year2 - year1;

    if (month2 < month1 || (month2 === month1 && day2 < day1)) {
      yearDifference--;
    }

    return yearDifference;
  }

  isValidCpr(cpr: string): boolean {
    // Check if the CPR number is exactly 10
    if (!/^\d{10}$/.test(cpr)) {
      return false;
    }

    // Extract the date part from the CPR number
    this.cprDay = parseInt(cpr.substring(0, 2), 10);
    this.cprMonth = parseInt(cpr.substring(2, 4), 10);
    this.cprYear = parseInt(cpr.substring(4, 6), 10);

    if (
      this.cprDay < 1 ||
      this.cprDay > 31 ||
      this.cprMonth < 1 ||
      this.cprMonth > 12
    ) {
      return false;
    }

    // Check last 4 digits
    const sequenceNumber = cpr.substring(6);
    if (!/^\d{4}$/.test(sequenceNumber)) {
      return false;
    }

    const age = this.getAgeBasedOnCpr();
    if (age < 18 || age > 120) {
      return false;
    }

    return true;
  }

  // This is move here and in Sjf so move to utility
  recalculateOnValidCpr() {
    const isValidCpr = this.isValidCpr(this.helvetiaCustomerInfo.cprNumber);
    this.errorInCprNumber = !isValidCpr;
    if (isValidCpr) {
      const age = this.getAgeBasedOnCpr();
      this.newAgeBasedOnCprSubject.next(age);
    }
  }

  extraUserAge: number = 0;

  recalculateOnValidCprExtraUser() {
    const cpr = this.helvetiaCustomerInfo.extraUserCprNumber;
    const isValidCpr = this.isValidCpr(cpr);

    if (isValidCpr) {
      const age = this.getAgeBasedOnCpr();
      this.extraUserAge = age;
      this.newAgeBasedOnExtraUserCprSubject.next(age);
      return;
    }

    if (this.extraUserAge != 0 && cpr.length == 0) {
      this.extraUserAge = 0;
      this.newAgeBasedOnExtraUserCprSubject.next(0);
      return;
    }
  }

  setAddress(address: DavaAddressInfo) {
    this.helvetiaCustomerInfo.selectedAddressInfo = address;
    this.errorInAddress = false;
    this.showAddressOption = false;
  }

  setExtraUserAddressAddress(address: DavaAddressInfo) {
    this.helvetiaCustomerInfo.extraUserSelectedAddressInfo = address;
    this.showExtraUserAddressOption = false;

    this.recalculateHelvetiaPrices();
  }

  resetCustomerInfoErrorFlags(): void {
    this.errorInCprNumber = false;
    this.errorInCvrNumber = false;
    this.errorInFirstname = false;
    this.errorInLastname = false;
    this.errorInCompanyName = false;
    this.errorInAddress = false;
    this.errorInEmail = false;
    this.errorInPhoneNumber = false;
    this.errorInExtraUserType = false;
    this.errorInExtraUserFirstname = false;
    this.errorInExtraUserLastname = false;
    this.errorInExtraUserCprNumber = false;
    this.errorInExtraUserCvrNumber = false;
    this.errorInExtraUserAddress = false;
    this.errorInExtraUserCompanyName = false;
  }

  validateCustomerInfo() {
    this.resetCustomerInfoErrorFlags();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const extraUserType = this.helvetiaCustomerInfo.extraUserType;
    const showAsBusinessCustomer =
      this.helvetiaCustomerInfo.showAsBusinessCustomer;
    const isCprValid = this.isValidCpr(this.helvetiaCustomerInfo.cprNumber);
    const isBusinessCustomer = showAsBusinessCustomer;
    const isCvrNumberEmpty = !this.helvetiaCustomerInfo.cvrNumber.trim();
    const isFirstnameEmpty = !this.helvetiaCustomerInfo.firstname.trim();
    const isLastnameEmpty = !this.helvetiaCustomerInfo.lastname.trim();
    const isCompanyNameEmpty = !this.helvetiaCustomerInfo.companyName.trim();
    const isAddressInfoEmpty =
      !this.helvetiaCustomerInfo.selectedAddressInfo.tekst;
    const isEmailEmpty = !this.helvetiaCustomerInfo.email.trim();
    const isEmailInvalid = !emailRegex.test(
      this.helvetiaCustomerInfo.email.trim(),
    );
    const isPhoneNumberEmpty = !this.helvetiaCustomerInfo.phoneNumber;
    const isPhoneNumberInvalidLength =
      this.helvetiaCustomerInfo.phoneNumber.toString().length !== 8;
    const isExtraUserTypeEmpty = !extraUserType;
    const isExtraUserCvrNumberEmpty =
      !this.helvetiaCustomerInfo.extraUserCvrNumber.trim();
    const isExtraUserCompanyNameEmpty =
      !this.helvetiaCustomerInfo.extraUserCompanyName.trim();
    const isExtraUserCprNumberEmpty =
      !this.helvetiaCustomerInfo.extraUserCprNumber.trim();
    const isExtraUserFirstnameEmpty =
      !this.helvetiaCustomerInfo.extraUserFirstname.trim();
    const isExtraUserLastnameEmpty =
      !this.helvetiaCustomerInfo.extraUserLastname.trim();
    const isExtraUserAddressInfoEmpty =
      !this.helvetiaCustomerInfo.extraUserSelectedAddressInfo.tekst;
    const isExtraUserCprNumberValid = this.isValidCpr(
      this.helvetiaCustomerInfo.extraUserCprNumber.trim(),
    );

    if (
      (!isCprValid && !isBusinessCustomer) ||
      (isCvrNumberEmpty && isBusinessCustomer) ||
      (isFirstnameEmpty && !isBusinessCustomer) ||
      (isLastnameEmpty && !isBusinessCustomer) ||
      (isCompanyNameEmpty && isBusinessCustomer) ||
      isAddressInfoEmpty ||
      isEmailEmpty ||
      isEmailInvalid ||
      isPhoneNumberEmpty ||
      isPhoneNumberInvalidLength ||
      (isExtraUserTypeEmpty && isBusinessCustomer) ||
      (extraUserType == 'business' &&
        isExtraUserCvrNumberEmpty &&
        isBusinessCustomer) ||
      (extraUserType == 'business' &&
        isExtraUserCompanyNameEmpty &&
        isBusinessCustomer) ||
      (extraUserType == 'private' &&
        isExtraUserCprNumberEmpty &&
        isBusinessCustomer) ||
      (extraUserType == 'private' &&
        isExtraUserFirstnameEmpty &&
        isBusinessCustomer) ||
      (extraUserType == 'private' &&
        isExtraUserLastnameEmpty &&
        isBusinessCustomer) ||
      (this.helvetiaCustomerInfo.extraUserType != 'none' &&
        isExtraUserAddressInfoEmpty &&
        isBusinessCustomer) ||
      (!isBusinessCustomer &&
        !isExtraUserCprNumberEmpty &&
        !isExtraUserCprNumberValid)
    ) {
      // Set error flags based on the conditions
      this.errorInExtraUserType = isExtraUserTypeEmpty && isBusinessCustomer;

      this.errorInExtraUserCvrNumber =
        extraUserType === 'business' && isExtraUserCvrNumberEmpty;

      this.errorInExtraUserCompanyName =
        extraUserType === 'business' && isExtraUserCompanyNameEmpty;

      if (isBusinessCustomer) {
        this.errorInExtraUserCprNumber =
          extraUserType === 'private' && isExtraUserCprNumberEmpty;
      } else {
        this.errorInExtraUserCprNumber =
          !isBusinessCustomer &&
          this.helvetiaCustomerInfo.extraUserCprNumber.trim().length > 0 &&
          !isExtraUserCprNumberValid;
      }

      this.errorInExtraUserFirstname =
        extraUserType === 'private' &&
        isBusinessCustomer &&
        isExtraUserFirstnameEmpty;

      this.errorInExtraUserLastname =
        extraUserType === 'private' &&
        isBusinessCustomer &&
        isExtraUserLastnameEmpty;

      this.errorInExtraUserAddress =
        extraUserType !== 'none' &&
        isExtraUserAddressInfoEmpty &&
        isBusinessCustomer;

      this.errorInCprNumber = !isCprValid && !isBusinessCustomer;

      this.errorInCvrNumber = isCvrNumberEmpty && isBusinessCustomer;

      this.errorInFirstname = isFirstnameEmpty && !isBusinessCustomer;

      this.errorInLastname = isLastnameEmpty && !isBusinessCustomer;

      this.errorInCompanyName = isCompanyNameEmpty && isBusinessCustomer;

      this.errorInAddress = isAddressInfoEmpty;

      this.errorInEmail = isEmailEmpty || isEmailInvalid;

      this.errorInPhoneNumber =
        isPhoneNumberEmpty || isPhoneNumberInvalidLength;

      return;
    }

    this.helvetiaCustomerInfo = {
      showAsBusinessCustomer: showAsBusinessCustomer,
      cprNumber: this.helvetiaCustomerInfo.cprNumber.trim(),
      cvrNumber: this.helvetiaCustomerInfo.cvrNumber.trim(),
      firstname: this.helvetiaCustomerInfo.firstname.trim(),
      lastname: this.helvetiaCustomerInfo.lastname.trim(),
      companyName: this.helvetiaCustomerInfo.companyName.trim(),
      selectedAddressInfo: this.helvetiaCustomerInfo.selectedAddressInfo,
      email: this.helvetiaCustomerInfo.email.trim(),
      phoneNumber: this.helvetiaCustomerInfo.phoneNumber.toString(),
      extraUserType: this.helvetiaCustomerInfo.extraUserType,
      extraUserCvrNumber: this.helvetiaCustomerInfo.extraUserCvrNumber?.trim(),
      extraUserCompanyName:
        this.helvetiaCustomerInfo.extraUserCompanyName?.trim(),
      extraUserCprNumber: this.helvetiaCustomerInfo.extraUserCprNumber?.trim(),
      extraUserFirstname: this.helvetiaCustomerInfo.extraUserFirstname.trim(),
      extraUserLastname: this.helvetiaCustomerInfo.extraUserLastname.trim(),
      extraUserSelectedAddressInfo:
        this.helvetiaCustomerInfo.extraUserSelectedAddressInfo,
    };

    this.setCustomerInfoEvent.emit(this.helvetiaCustomerInfo);
    this.nextStepEvent.emit();
  }
}
