<div class="sm:max-w-lg sm:w-full sm:mx-auto">
  <div
    class="flex flex-col bg-white border shadow-sm pointer-events-auto dark:bg-gray-800 dark:border-gray-700 dark:shadow-slate-700/[.7]"
  >
    <div
      class="flex justify-between items-center py-3 px-4 border-b dark:border-gray-700"
    >
      <h3 class="font-bold text-gray-800 dark:text-white">{{ title }}</h3>
      <button
        (click)="cancel()"
        type="button"
        class="flex justify-center items-center focus:outline-none size-7 text-sm font-semibold rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:hover:bg-gray-700"
        data-hs-overlay="#hs-basic-modal"
      >
        <span class="sr-only">Close</span>
        <svg
          class="flex-shrink-0 size-4"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="M18 6 6 18"></path>
          <path d="m6 6 12 12"></path>
        </svg>
      </button>
    </div>
    <div class="p-4 overflow-y-auto">
      <p class="mt-1 text-gray-800 dark:text-gray-400">
        {{ message }}
      </p>
    </div>
    <div
      class="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-gray-700"
    >
      <button
        (click)="cancel()"
        type="button"
        class="py-2 px-3 focus:outline-none inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800"
        data-hs-overlay="#hs-basic-modal"
      >
        Annuller
      </button>
      <button
        (click)="confirm()"
        type="button"
        class="py-2 px-3 focus:outline-none inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-teal-500 text-white hover:bg-teal-600 disabled:opacity-50 disabled:pointer-events-none"
      >
        {{ confimText }}
      </button>
    </div>
  </div>
</div>
