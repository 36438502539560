<div
  [ngClass]="{
    ' bg-teal-100 border border-teal-200 text-teal-800': type == 'success',
    'bg-red-100 border border-red-200 text-red-800': type == 'error'
  }"
  class="w-full text-sm rounded-lg"
  role="alert"
>
  <div class="flex p-4 text-center items-center gap-2 font-inter">
    <i *ngIf="type == 'error'" class="fa-solid fa-circle-exclamation"></i>
    <i *ngIf="type == 'success'" class="fa-solid fa-circle-check"></i>
    <p class="">{{ message }}</p>

    <div class="ms-auto flex text-center items-center">
      <button
        [ngClass]="{
          'text-teal-800': type == 'success',
          'text-red-800': type == 'error'
        }"
        (click)="cancel()"
        type="button"
        class="size-5 rounded-lg opacity-50 hover:opacity-100 focus:outline-none focus:opacity-100"
      >
        <i class="fa-solid fa-x"></i>
      </button>
    </div>
  </div>
</div>
