import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatSliderModule } from '@angular/material/slider';

import { SnackbarComponent } from './basic-components/snackbar/snackbar.component';
import { DropdownComponent } from './basic-components/dropdown/dropdown.component';
import { SearchableSelectComponent } from './basic-components/searchable-select/searchable-select.component';
import { InputComponent } from './basic-components/input/input.component';

import { HelvetiaPriceCardComponent } from './components/helvetia-price-card/helvetia-price-card.component';
import { HelvetiaInfoComponent } from './components/helvetia-info/helvetia-info.component';
import { HelvetiaCoverageSelectionComponent } from './components/helvetia-coverage-selection/helvetia-coverage-selection.component';
import { HelvetiaCustomerInfoComponent } from './components/helvetia-customer-info/helvetia-customer-info.component';
import { HelvetiaAcceptConditionsComponent } from './components/helvetia-accept-conditions/helvetia-accept-conditions.component';
import { HelvetiaSideDisplayComponent } from './components/helvetia-side-display/helvetia-side-display.component';

import { LucideAngularModule, RotateCcw } from 'lucide-angular';

import { StepperComponent } from './components/stepper/stepper.component';
import { SuccessStepComponent } from './components/success-step/success-step.component';

import { TruncatePipe } from './pipes/truncate.pipe';
import { FormatNumberPipe } from './pipes/format-number.pipe';
import { NotificationDialogComponent } from './components/notification-dialog/notification-dialog.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { ErrorPriceCardComponent } from './components/error-price-card/error-price-card.component';
import { VehicleInfoComponent } from './components/vehicle-info/vehicle-info.component';

@NgModule({
  declarations: [
    HelvetiaPriceCardComponent,
    HelvetiaInfoComponent,
    HelvetiaCoverageSelectionComponent,
    HelvetiaCustomerInfoComponent,
    HelvetiaAcceptConditionsComponent,
    HelvetiaSideDisplayComponent,
    TruncatePipe,
    FormatNumberPipe,
    InputComponent,
    SearchableSelectComponent,
    DropdownComponent,
    SnackbarComponent,
    StepperComponent,
    SuccessStepComponent,
    NotificationDialogComponent,
    ConfirmationDialogComponent,
    ErrorPriceCardComponent,
    VehicleInfoComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatSnackBarModule,
    MatSliderModule,
    MatDialogModule,
    LucideAngularModule.pick({
      RotateCcw,
    }),
  ],
  exports: [
    HelvetiaPriceCardComponent,
    HelvetiaInfoComponent,
    HelvetiaCoverageSelectionComponent,
    HelvetiaCustomerInfoComponent,
    HelvetiaAcceptConditionsComponent,
    HelvetiaSideDisplayComponent,
    StepperComponent,
    SearchableSelectComponent,
    InputComponent,
    SuccessStepComponent,
    DropdownComponent,
    ErrorPriceCardComponent,
    ConfirmationDialogComponent,
    VehicleInfoComponent,
  ],
})
export class SharedModule {}
