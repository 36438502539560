import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DmrResponse } from '../../models/dmr.model';
import { HelvetiaVariant } from '../../models/helvetia.model';
import { LibUtilityService } from '../../utils';

@Component({
  selector: 'lib-helvetia-coverage-selection',
  templateUrl: './helvetia-coverage-selection.component.html',
  styleUrls: ['./helvetia-coverage-selection.component.scss'],
})
export class HelvetiaCoverageSelectionComponent {
  @Output() setGlassCoverEvent = new EventEmitter<boolean>();
  @Output() setParkingCoverageEvent = new EventEmitter<boolean>();
  @Output() setRoadsideAssistanceEvent = new EventEmitter<boolean>();
  @Output() setChargingStandEvent = new EventEmitter<boolean>();
  @Output() nextStepEvent = new EventEmitter();

  @Input() selectedHelvetiaVariant: HelvetiaVariant = {} as HelvetiaVariant;
  @Input() showYearlyPrice: boolean = false;
  @Input() glassCover: boolean = false;
  @Input() parkingCoverage: boolean = false;
  @Input() roadsideAssistance: boolean = false;
  @Input() chargingStand: boolean = false;
  @Input() textDirectedToCustomer: boolean = false;
  @Input() allHaveToggleChargingStandAllowed: boolean = false;
  @Input() vehicleFromDmr: DmrResponse = {} as DmrResponse;

  showTooltip_ParkingCoverage: boolean = false;
  showTooltip_RoadsideAssistance: boolean = false;
  showTooltip_GlassCover: boolean = false;

  constructor(private libUtilityService: LibUtilityService) {}

  toggleParkingCoverage() {
    this.parkingCoverage = !this.parkingCoverage;
    this.setParkingCoverageEvent.emit(this.parkingCoverage);
  }

  toggleRoadsideAssistance() {
    this.roadsideAssistance = !this.roadsideAssistance;
    this.setRoadsideAssistanceEvent.emit(this.roadsideAssistance);
  }

  toggleGlassCoverCoverage() {
    this.glassCover = !this.glassCover;
    this.setGlassCoverEvent.emit(this.glassCover);
  }

  toggleChargingStandCoverage() {
    this.chargingStand = !this.chargingStand;
    this.setChargingStandEvent.emit(this.chargingStand);
  }

  getHelvetiaParkingCoveragePrice() {
    return this.libUtilityService.getHelvetiaParkingCoveragePrice(
      this.selectedHelvetiaVariant.coverages,
      this.showYearlyPrice
    );
  }

  getHelvetiaRoadsideAssistancePrice() {
    return this.libUtilityService.getHelvetiaRoadsideAssistancePrice(
      this.selectedHelvetiaVariant.coverages,
      this.showYearlyPrice
    );
  }

  getHelvetiaGlassCoverPrice() {
    return this.libUtilityService.getHelvetiaGlassCoverPrice(
      this.selectedHelvetiaVariant.coverages,
      this.showYearlyPrice
    );
  }

  nextStep() {
    this.nextStepEvent.emit();
  }
}
