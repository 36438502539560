<div class="max-w-[85rem] lg:px-8 lg:py-10 mx-auto text-[#34C0A7]">
  <div *ngIf="activeStepIndex < 7">
    <div class="flex items-center mb-2">
      <div class="font-inter text-base font-medium leading-[16.94px]">
        Step {{ activeStepIndex + 1 }}
      </div>

      <button
        *ngIf="activeStepIndex > 0"
        (click)="restart()"
        class="flex items-center justify-center w-5 h-5 ml-4 bg-[#F1FCF9] hover: text-[#34C0A7] text-[#174A44] rounded cursor-pointer"
      >
        ↻
      </button>
    </div>
    <div class="relative">
      <div
        class="flex w-full h-1 bg-[#E2E6F9] absolute mt-1 rounded-full"
        role="progressbar"
      ></div>
      <div
        class="flex flex-col justify-center absolute rounded-full h-3 overflow-hidden bg-[#34C0A7] text-xs text-white text-center whitespace-nowrap transition duration-500 dark:bg-blue-500 relative z-10"
        [style.width]="calculateWidth()"
      ></div>
    </div>
    <button
      *ngIf="activeStepIndex > 0"
      type="button"
      class="inline-flex items-center pt-2 gap-x-2 leading-[16.94px] font-normal rounded-lg border border-transparent text-[#34C0A7] hover:text-teal-600 disabled:opacity-50 disabled:pointer-events-none dark:text-gray-800 dark:hover:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
      (click)="previousStep()"
    >
      <i class="fa-solid fa-arrow-left tex-sm"></i>
      Tilbage
    </button>
  </div>
</div>
